import { Injectable } from "@angular/core";

@Injectable()
export class DateService {
    fromConfig(str: string): Date | undefined {
        return this.fromDisplayString(str);
    }

    toDisplayString(date: Date | undefined): string {
        if (date) {
            let m: string = pad(date.getMonth() + 1);
            let d: string = pad(date.getDate());
            let y: string = date.getFullYear() + "";
            
            return `${m}/${d}/${y}`;
        } else {
            return "";
        }        
    }
    
    fromDisplayString(str: string): Date | undefined {
        let parsedDate: number = Date.parse(str);
        if (!isNaN(parsedDate)) {
            return new Date(parsedDate);
        } else {
            return undefined;
        }
    }

    validateDateString(value: string): boolean {
        return (this.fromDisplayString(value) ? true : false);
    }
}

function pad(value: number): string {
    if (value < 10) {
        return "0" + value;
    } else {
        return "" + value;
    }
}
