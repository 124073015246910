import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { ReplaySubject } from "rxjs/ReplaySubject"

import { DocumentList } from "../internal";

@Injectable()
export class MessengerService {
    // Search message
    private searchSource = new ReplaySubject<Observable<DocumentList>>(1);
    search$ = this.searchSource.asObservable();
    search(data: Observable<DocumentList>) {
        this.searchSource.next(data);
    }
    
    // add other messages here, as necessary
}
