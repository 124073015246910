import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

import { SearchFormService } from "../internal";

@Component({
    selector: "obpa-search-form-fulltext",
    templateUrl: "./search-form-fulltext.component.html"
})
export class SearchFormFulltextComponent {
    constructor(public searchForm: SearchFormService) {}

    @Input()
    fulltextFormControl: FormControl;
}
