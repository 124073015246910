import {
    Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,
    ChangeDetectionStrategy, NgZone
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import {
    Column, BoundColumn, UnboundColumn,
    DocumentHitlistConfigService
} from "../internal";

import {
    DisplayColumn, DisplayColumnValue, Document,
    KeywordDataType
} from "../../shared";

@Component({
    selector: "obpa-document-hitlist-grid",
    templateUrl: "./document-hitlist-grid.component.html",
    styleUrls: ["./document-hitlist-grid.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentHitlistGridComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    documents: Document[];

    @Input()
    columns: DisplayColumn[];

    @Input()
    isFullText: boolean;

    @Input()
    isMobileBrowser: boolean;

    @Output() documentOpen = new EventEmitter<Document>();
    @Output() documentOpenInNewWindow = new EventEmitter<Document>();

    gridElement?: JQuery;

    constructor(
        private zone: NgZone,
        private config: DocumentHitlistConfigService,
        private translate: TranslateService) { }

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            if (!this.gridElement) {
                this.initializeGrid();
            }
        });

        this.translate.onLangChange.subscribe(() => {
            this.zone.runOutsideAngular(() => this.retranslateGrid());
        });
    }

    ngOnChanges(changes: any) {
        this.zone.runOutsideAngular(() => {
            this.destroyGrid();
            this.initializeGrid();
        });
    }

    ngOnDestroy() {
        this.destroyGrid();
    }

    initializeGrid() {
        let displayData = this.generateDisplayData();

        this.gridElement = $('#obpa-grid').igGrid({
            autoGenerateColumns: false,
            autofitLastColumn: true,
            primaryKey: "obpa_id",

            columns: displayData.columns,
            dataSource: displayData.documents,            

            alternateRowStyles: false,

            // we will control the tab-order elsewhere
            tabIndex: -1,

            features: <IgGridFeature[]>[
                <IgGridSorting>{
                    name: "Sorting",

                    // remove blue style on sorted column
                    applySortedColumnCss: false,

                    // each search should default to database sort
                    persist: false,

                    columnSettings: <IgGridSortingColumnSetting[]>[
                        { columnKey: "obpa_action", allowSorting: false },
                        { columnKey: "obpa_gap", allowSorting: false }
                    ]
                },
                <IgGridSelection>{
                    name: "Selection",

                    mode: "row",

                    wrapAround: false,

                    // only use the "activeRow" functionality, ignore any row selections
                    rowSelectionChanging: (event, ui) => {
                        return false;
                    }
                },
                <IgGridResizing>{
                    name: "Resizing",

                    columnSettings: <IgGridResizingColumnSetting[]>[
                        { columnKey: "obpa_action", allowResizing: false },
                        { columnKey: "obpa_gap", allowResizing: false }
                    ]
                }
            ],

            cellClick: (event, ui) => {
                this.emitDocumentOpen(ui.rowKey);
            },

            columnsCollectionModified: (event, ui) => {
                let grid: IgGridMethods = ui.owner;
                let gridElement: JQuery = ui.owner.element;

                // the first cell should be tab-able
                gridElement.find("thead:eq(0) th").attr("tabindex", "-1").first().attr("tabindex", "0");
            },

            rowsRendered: (event, ui) => {
                let grid: IgGridMethods = ui.owner;
                let gridElement: JQuery = ui.owner.element;

                let gridRows: JQuery = grid.rows() as any;

                // add action buttons
                this.populateActionCells(gridRows);

                // the first row should be tab-able
                gridRows.first().attr("tabindex", "0");

                grid.autoSizeColumns();
            },

            rendered: (event, ui) => {
                let grid: IgGridMethods = ui.owner;
                let gridElement: JQuery = ui.owner.element;

                // headers should come first in the DOM
                gridElement.find("tbody:eq(0)").before(gridElement.find("thead:eq(0)"));

                // keyboard navigation within the header
                gridElement.find("thead:eq(0)").keydown(event => {
                    let handled = false;

                    switch (event.keyCode) {
                        case 40: // down
                            (grid.rows() as any as JQuery).first().focus();
                            handled = true;
                            break;
                        case 37: // left
                            $(event.target).prev().focus();
                            handled = true;
                            break;
                        case 39: // right
                            $(event.target).next().focus();
                            handled = true;
                            break;
                    }

                    if (handled) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                });

                // keyboard navigation within the body
                gridElement.find("tbody:eq(0)").keydown(event => {
                    let handled = false;

                    let rowInfo: { rowId: number, rowIndex: number } = grid.getElementInfo(event.target) as any;

                    switch (event.keyCode) {
                        case 38: // up
                            if (rowInfo.rowIndex == 0) {
                                gridElement.igGridSelection("clearSelection");
                                gridElement.find("thead:eq(0) th").first().focus();
                                handled = true;
                            }
                            break;
                        case 13: // enter
                        case 32: // space
                            if (rowInfo.rowId) {
                                this.emitDocumentOpen(rowInfo.rowId);
                                handled = true;
                            }
                            break;
                    }

                    if (handled) {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    }
                });
            }
        });

        this.translateHeaders();
    }

    destroyGrid() {
        if (this.gridElement) {
            this.gridElement.igGrid("destroy");
            this.gridElement = undefined;
        }
    }

    // We need to massage the Document list to get it in a format the IgGrid can consume.
    // 1) Create columns based on the current state of @Input's.
    // 2) Map each Document to an object, with properties matching the columns from (1).
    // Note that IgGrid does NOT consume the Document list directly, due to limitations mapping properties to columns.

    generateDisplayData(): { columns: Column[], documents: any[] } {
        return {
            columns: this.generateDisplayColumns(),
            documents: this.generateDisplayDocuments()
        };
    }

    generateDisplayColumns(): Column[] {
        let displayColumns: Column[] = [];

        // common columns
        displayColumns.push(new BoundColumn({
            key: "obpa_id",
            width: "34",
            visible: false
        }));
        if (!this.isMobileBrowser) displayColumns.push(new UnboundColumn({
            key: "obpa_action",
            width: "*",
            columnCssClass: "js-obpa_action"
        }));

        if (this.columns && this.columns.length > 0) {
            // create custom headers
            for (let i = 0, len = this.columns.length; i < len; i++) {

                // Sorting requires that the visible column will be of its type (NOT a string).
                // In cases (currency, date) the string we want to display does not quite match its backing value...
                // 1. create a hidden column, holding the string to display ($1,000.00)
                // 2. create a visible column, bound to the raw value (1000), BUT templating the hidden column

                let keyForData: string = `custom${i}`;
                let keyForDisplay: string = `${keyForData}display`;

                switch (this.columns[i].dataType) {
                    default:
                    case KeywordDataType.Null:
                    case KeywordDataType.AlphaNumeric:
                    case KeywordDataType.AlphaNumericSingleTable:
                    case KeywordDataType.AlphaNumericCSInsensitiveSearch:
                    case KeywordDataType.AlphaNumericSingleTableCSInsensitiveSearch:
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            dataType: "string"
                        }));
                        break;
                    case KeywordDataType.LargeNumeric:
                    case KeywordDataType.SmallNumeric:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "number"
                        }));
                        break;
                    case KeywordDataType.Currency:
                    case KeywordDataType.SpecificCurrency:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "number"
                        }));
                        break;
                    case KeywordDataType.Float:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "number"
                        }));
                        break;
                    case KeywordDataType.Date:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "date"
                        }));
                        break;
                    case KeywordDataType.DateTime:
                        displayColumns.push(new BoundColumn({
                            key: keyForDisplay,
                            visible: false
                        }));
                        displayColumns.push(new BoundColumn({
                            key: keyForData,
                            width: "*",
                            header: this.columns[i].heading,
                            template: `{{html ${keyForDisplay}}}`,
                            dataType: "date"
                        }));
                        break;
                }
            }
        } else {
            // use default headers
            if (this.isFullText) displayColumns.push(new BoundColumn({
                key: "obpa_fulltext_score",
                width: "*",
                translateHeader: true,
                dataType: "number",
                format: "double"
            }));
            displayColumns.push(new BoundColumn({
                key: "obpa_name",
                width: "*",
                translateHeader: true
            }));
            if (this.isFullText) displayColumns.push(new BoundColumn({
                key: "obpa_fulltext_summary",
                width: "*",
                translateHeader: true
            }));
        }

        // With "autofitLastColumn", this empty column will...
        // 1) if table width <  100%, expand to fill the gap
        // 2) if table width >= 100%, not be visible
        displayColumns.push(new UnboundColumn({
            key: "obpa_gap"
        }));

        return displayColumns;
    }

    generateDisplayDocuments(): any[] {
        let displayDocuments: any[] = [];

        if (this.columns && this.columns.length > 0) {
            // create custom headers
            displayDocuments = this.documents.map((document, index) => {
                let displayDocument: any = {
                    "obpa_id": index
                };
                for (let i = 0, len = this.columns.length; i < len; i++) {

                    // Sorting requires that the visible column will be of its type (NOT a string).
                    // In cases (currency, date) the string we want to display does not quite match its backing value...
                    // 1. create a hidden column, holding the string to display ($1,000.00)
                    // 2. create a visible column, bound to the raw value (1000), BUT templating the hidden column

                    let keyForData: string = `custom${i}`;
                    let keyForDisplay: string = `${keyForData}display`;

                    // this must exist in the array if the API is doing the right thing
                    let displayColumnValue: DisplayColumnValue = (document.displayColumnValues as any)[i];

                    switch (this.columns[i].dataType) {
                        default:
                        case KeywordDataType.Null:
                        case KeywordDataType.AlphaNumeric:
                        case KeywordDataType.AlphaNumericSingleTable:
                        case KeywordDataType.AlphaNumericCSInsensitiveSearch:
                        case KeywordDataType.AlphaNumericSingleTableCSInsensitiveSearch:
                            // leave the value as it is
                            displayDocument[keyForData] = displayColumnValue.value;
                            break;
                        case KeywordDataType.LargeNumeric:
                        case KeywordDataType.SmallNumeric:
                            // parse and treat as number
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? parseInt(displayColumnValue.raw) : undefined;
                            break;
                        case KeywordDataType.Currency:
                        case KeywordDataType.SpecificCurrency:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? parseFloat(displayColumnValue.raw) : undefined;
                            break;
                        case KeywordDataType.Float:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? parseFloat(displayColumnValue.raw) : undefined;
                            break;
                        case KeywordDataType.Date:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? new Date(parseInt(displayColumnValue.raw)) : undefined;
                            break;
                        case KeywordDataType.DateTime:
                            displayDocument[keyForDisplay] = displayColumnValue.value;
                            displayDocument[keyForData] = displayColumnValue.raw ? new Date(parseInt(displayColumnValue.raw)) : undefined;
                            break;
                    }
                }

                return displayDocument;
            });
        } else {
            // use default headers
            displayDocuments = this.documents.map((document, index) => {
                return {
                    "obpa_id": index,
                    "obpa_fulltext_score": document.fullTextScore,
                    "obpa_name": document.docName,
                    "obpa_fulltext_summary": document.fullTextSummary
                };
            });
        }

        return displayDocuments;
    }

    populateActionCells(rows: JQuery) {
        rows.each((index: number, _elem: Element) => {
            let elem = $(_elem);

            // add action buttons
            let id = +elem.attr("data-id")!;
            let cell = elem.find(".js-obpa_action");

            let cellContents = $("<div style='white-space:nowrap;'/>");

            // new window
            cellContents.append(
                $("<button/>")
                    .addClass("btn btn-xs btn-link")
                    .attr("title", this.translate.instant("OPEN IN NEW WINDOW"))
                    .attr("aria-hidden", "true")
                    .attr("tabindex", "-1")
                    .click(event => {
                        this.emitDocumentOpenInNewWindow(id);
                        event.preventDefault();
                        event.stopPropagation();
                    })
                    .keydown(event => {
                        // enter, space
                        if (event.keyCode === 13 || event.keyCode === 32) {
                            this.emitDocumentOpenInNewWindow(id);
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    })
                    .append("<span class='glyphicon glyphicon-new-window' aria-hidden='true'/>")
            );

            cell.empty().append(cellContents);
        });
    }

    emitDocumentOpen(id: number) {
        this.zone.run(() => {
            let document = this.documents[id];
            this.documentOpen.emit(document);
        });
    }

    emitDocumentOpenInNewWindow(id: number) {
        this.zone.run(() => {
            let document = this.documents[id];
            this.documentOpenInNewWindow.emit(document);
        });
    }

    translateHeaders() {
        if (!this.gridElement) return;

        // gather necessary keys
        let keys: string[] = (this.gridElement.igGrid("option", "columns") as Column[])
            .filter(column => column.translateHeader)
            .map(column => `HDR-${column.key}`);
        if (keys.length === 0) return;

        let translatedKeys = this.translate.instant(keys);

        // each <th> has id="{gridId}_{columnKey}"
        let columnHeaderKeyPosition = this.gridElement.attr("id")!.length + 1;

        // gather existing header elements (and ignore the mistyped headersTable)
        let columnHeaders: JQuery = (this.gridElement.igGrid("headersTable") as any).find("th");
        columnHeaders.each((index, header) => {
            // determine key, and translate if the string is available
            let key = `HDR-${header.id.substring(columnHeaderKeyPosition)}`;
            if (key in translatedKeys) {
                $(header).find(".ui-iggrid-headertext").text(translatedKeys[key]);
            }
        });

        // the igGrid element must be visible before autSizeColumns gets called.
        // Firefox hides the element until it is fully rendered.
        this.gridElement.parent().show();
        this.gridElement.igGrid("autoSizeColumns");
    }

    retranslateGrid() {
        if (!this.gridElement) return;

        this.translateHeaders();

        let gridRows: JQuery = this.gridElement.igGrid("rows") as any;
        this.populateActionCells(gridRows);
    }
}
