import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

import { SearchFormService } from "../internal";

@Component({
    selector: "obpa-search-form-date",
    templateUrl: "./search-form-date.component.html"
})
export class SearchFormDateComponent {
    constructor(public searchForm: SearchFormService) {}

    @Input()
    dateID: number;

    @Input()
    dateTitle: string;

    @Input()
    dateFormControl: FormControl;
}
