import { Column } from "../internal";

export class BoundColumn extends Column {
    public readonly dataType: string;
    public readonly format: string | undefined;
    public readonly template: string | undefined;

    constructor(init: {
        key: string,
        visible?: boolean,
        width?: string,
        header?: string,
        translateHeader?: boolean,
        dataType?: string,
        format?: string,
        template?: string
    }) {
        super(init);
        this.dataType = init.dataType || "string";
        this.format = init.format;
        this.template = init.template;
    }
} 
