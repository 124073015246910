import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { CustomQuery,
         SearchApiService, SearchConfigService } from "../internal";

import { StatusComponentStatus } from "../../shared";

@Component({
    selector: "obpa-query-selector",
    templateUrl: "./query-selector.component.html",
    styleUrls: ["./query-selector.component.css"]
})
export class QuerySelectorComponent implements OnInit {
    status: StatusComponentStatus = StatusComponentStatus.Loading;

    @Input()
    selection: CustomQuery;
    
    @Output()
    selectionChange = new EventEmitter();
    
    queries: CustomQuery[] = [];
    showSingleQuery: boolean;
    initialSelectedQueryId?: number;
    
    constructor(
        private api: SearchApiService, 
        private config: SearchConfigService
    ) {
        this.showSingleQuery = config.showSingleQuery;
        this.initialSelectedQueryId = config.selectedQueryId;
    }
    
    ngOnInit() {
        this.loadQueries();
    }
    
    loadQueries() {
        this.status = StatusComponentStatus.Loading;
        this.api.getCustomQueries().subscribe(
            queries => { // success
                this.queries = queries;
                
                // select initial query, if it is specified and valid
                let initialSelectedQuery = this.queries.find(query => query.id == this.initialSelectedQueryId);
                if (initialSelectedQuery) {
                    this.onSelectionChange(initialSelectedQuery);
                } else {
                    // select the first query, if it exists
                    if (this.queries.length > 0) {
                        this.onSelectionChange(this.queries[0]);
                    }
                }
                
                this.status = StatusComponentStatus.Normal;
            }, 
            err => { //fail
                console.error(err);
                this.status = StatusComponentStatus.Error;
            }
        );
    }
    
    onSelectionChange(newSelection: CustomQuery) {
        if (this.selection === newSelection) return;
        this.selection = newSelection;
        this.selectionChange.emit(newSelection);
    }
        
    onRetry() {
        this.loadQueries();
    }
}
