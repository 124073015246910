<div *ngIf="!isHidden">
    <div *ngIf="showDoctypes" class="well well-sm">
        <obpa-doctype-selector
            [(selection)]="selectedDoctype" 
            (selectionChange)="onSelectedDoctypeChange($event)">
        </obpa-doctype-selector>
    </div>
    
    <obpa-status
        [status]="status"
        error="{{'ERROR: KEYWORDS'|translate}}"
        (retry)="onRetry()">
    
        <obpa-search-form *ngIf="!showDoctypes || searchFormModel"
            [searchFormModel]="searchFormModel"
            (search)="performManualSearch($event.value)">
        </obpa-search-form>
    
    </obpa-status>
</div>