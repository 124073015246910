import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/retry';

import {
    KeywordResponse,
    ConfigService
} from '../internal';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
    private URI: string;

    constructor(private http: HttpClient, private config: ConfigService) {
        // default to "{root}/api" if unspecified
        this.URI = this.config.get('api.url') || '/api';
    }

    requestCustomQueries(): Observable<any> {
        return this.http
            .get(`${this.URI}/CustomQuery`).pipe(
                catchError(this.catchHttpError)
            )
    }

    requestDocTypes(): Observable<any> {
        return this.http
            .get(`${this.URI}/DocumentType`).pipe(
                catchError(this.catchHttpError)
            )
    }

    requestKeywords(params: GetKeywordsParams): Observable<any> {
        let body = JSON.stringify(params);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = ({ headers: headers });

        return this.http
            .post(`${this.URI}/Keywords`, body, options)
            .pipe(
                catchError(this.catchHttpError)
            )
    }

    requestDocumentsByKeyword(params: GetDocumentsByKeywordParams): Observable<any> {
        let body = JSON.stringify(params);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = ({ headers: headers });

        return this.http
            .post(`${this.URI}/CustomQuery/KeywordSearch`, body, options).pipe(
                catchError(this.catchHttpError)
            )
    }

    requestDocumentsByFullText(params: GetDocumentsByFullTextParams): Observable<any> {
        let body = JSON.stringify(params);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = ({ headers: headers });
        return this.http
            .post(`${this.URI}/DocumentType/FullTextSearch`, body, options).pipe(
                catchError(this.catchHttpError)
            )
    }

    getDocumentUri(docID: string): string {
        // trailing forward slash hides docID from Chrome's PDF viewer
        let fullUri = `${this.URI}/Document/${encodeURIComponent(docID)}/`;
        return fullUri;
    }

    tryDocumentUri(docID: string): Observable<any> {
        let body = "{}";
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = ({ headers: headers });

        return this.http
            .post(this.getDocumentUri(docID), body, options).pipe(
                catchError(this.catchHttpError)
            )
    }

    private catchHttpError(error: any) {
        let errorMessage: string;
        if (error.message) {
            errorMessage = `ApiService HTTP error: ${error.message}`;
        } else if (error.status) {
            errorMessage = `ApiService HTTP error: ${error.status} - ${error.statusText}`;
        } else {
            errorMessage = "ApiService HTTP error."
        }

        console.log(errorMessage);
        return throwError(errorMessage);
    }
}

export interface GetKeywordsParams {
    QueryID?: number;
    DocTypeID?: number;
}

export interface GetDocumentsByKeywordParams {
    QueryID?: number;
    Keywords?: KeywordResponse[];
    FromDate?: Date;
    ToDate?: Date;
    FolderID?: string;
    QueryLimit?: number;
}

export interface GetDocumentsByFullTextParams {
    SearchText?: string;
    FromDate?: Date;
    ToDate?: Date;
    QueryID?: number;
    DocTypeID?: number;
    Keywords?: KeywordResponse[];
    QueryLimit?: number;
}
