export abstract class Column implements IgGridColumn {

    public readonly key: string;
    public readonly hidden: boolean;

    public readonly width: string;
    
    public readonly headerText: string;
    public readonly translateHeader: boolean;

    constructor(init: {
        key: string,
        visible?: boolean,
        width?: string,
        header?: string,
        translateHeader?: boolean
    }) {
        this.key = init.key;
        this.hidden = (init.visible !== undefined) ? !init.visible : false;

        this.width = init.width || "";

        this.headerText = init.header || "";
        this.translateHeader = init.translateHeader || false;
    }
}
