import { Column } from "../internal";

export class UnboundColumn extends Column {
    public readonly unbound: boolean = true;
    public readonly columnCssClass: string;

    constructor(init: {
        key: string,
        visible?: boolean,
        width?: string,
        header?: string,
        translateHeader?: boolean,
        columnCssClass?: string
    }) {
        super(init);
        this.columnCssClass = init.columnCssClass || "";
    }
}
