import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import 'rxjs/add/operator/toPromise';

import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";

import { BundledTranslateLoader } from "./internal";

import { LoadingComponent, StatusComponent,
         ApiService, MessengerService, QueryStringService, ConfigService } from "./internal";

// INIT_FACTORY is run in the root AppModule upon bootstrap, and returns a promise.
// If the promise resolves successfully, the components continue to bootstrap.
// If the promise fails, the app does not bootstrap the components.
// SO, we initialize config and translations BEFORE the components bootstrap, failing if no config is loaded.

export const INIT_DEPENDENCIES = [ConfigService, TranslateService];
export function INIT_FACTORY(config: ConfigService, translate: TranslateService) {
    return () => {
        // load configuration, then initialize language service
        return config
            .load().toPromise()
            .then(() => {
                // load english as fallback
                translate.setDefaultLang("en");

                // set language by configuration
                let configLanguage = config.getStringOrDefault("language.default", "en");
                if (BundledTranslateLoader.isSupported(configLanguage)) {
                    translate.use(configLanguage);
                } else {
                    // default to english
                    console.error(`Unsupported language in configuration, default to en: ${configLanguage}`);
                    translate.use("en");
                }
            });
    };
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: BundledTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        LoadingComponent,
        StatusComponent
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        StatusComponent,
        LoadingComponent
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: SharedModule,
            providers: [
                // app initialization
                {
                    provide: APP_INITIALIZER, multi: true,
                    deps: INIT_DEPENDENCIES,
                    useFactory: INIT_FACTORY
                },
                // other singleton services
                ConfigService,
                ApiService,
                MessengerService,
                QueryStringService
            ]
        };
    }
}