import { DisplayColumn, Document } from "../internal";

export class DocumentList {
    public readonly documents: Document[];
    public readonly displayColumns?: DisplayColumn[];
    public readonly isTruncated: boolean;
    public readonly isFullText: boolean;

    constructor(init: {
        documents: Document[],
        displayColumns?: DisplayColumn[]
        isTruncated?: boolean
    }) {
        this.documents = init.documents || [];
        this.displayColumns = init.displayColumns;
        this.isTruncated = init.isTruncated || false;

        this.isFullText = this.documents ? this.documents.some(document => document.isFullText) : false;
    }
}
