// Main Entry Point
import "./dependencies";

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";

const METADATA = require("../metadata.json");

function bootstrap() {
    // log the version/build to console
    console.log(`${METADATA.TITLE} ${METADATA.VERSION}`);

    // production mode
    if (process.env.NODE_ENV === "production") {
        enableProdMode();
    }

    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
}

function bootstrapWhenDomReady() {
    document.addEventListener("DOMContentLoaded", bootstrap);
}

//bootstrap();
bootstrapWhenDomReady();
