import { Component, OnInit } from "@angular/core";

import { Observable } from "rxjs/Observable";

import { DocumentHitlistApiService, DocumentHitlistConfigService, MobileDetectorService } from "../internal";

import { Document, DocumentList, StatusComponentStatus, SingleSubscription,
         MessengerService } from "../../shared";

@Component({
    selector: "obpa-document-hitlist",
    templateUrl: "./document-hitlist.component.html",
    styleUrls: ["./document-hitlist.component.css"],
    providers: [
        DocumentHitlistApiService,
        DocumentHitlistConfigService,
        MobileDetectorService
    ]
})
export class DocumentHitlistComponent implements OnInit {
    status: StatusComponentStatus = StatusComponentStatus.Normal;
    subscription: SingleSubscription = new SingleSubscription();

    isMobileBrowser: boolean;

    documentList?: DocumentList;

    latestSearch?: Observable<DocumentList>;

    currentDocument?: Document;

    constructor(
        private api: DocumentHitlistApiService,
        private config: DocumentHitlistConfigService,
        private messenger: MessengerService,
        private mobileDetector: MobileDetectorService
    ) {
        this.isMobileBrowser = mobileDetector.isMobileBrowser();
    }

    ngOnInit() {
        this.messenger.search$.subscribe((search: Observable<DocumentList>) => {
            this.requestDocumentsAndDisplay(search);
        });
    }

    requestDocumentsAndDisplay(search: Observable<DocumentList>) {
        this.subscription.cancel();
        this.documentList = undefined;
        this.status = StatusComponentStatus.Loading;

        this.latestSearch = search;
        this.subscription.store(search.subscribe(
            docList => {
                this.documentList = docList;
                this.status = StatusComponentStatus.Normal;

                // autoview
                if (docList.documents.length === 1 && this.config.autoDisplaySingleDoc()) {
                    this.onDocumentOpen(docList.documents[0]);
                }
            },
            err => {
                console.error(err);
                this.status = StatusComponentStatus.Error;
            }
        ));
    }

    onRetry() {
        if (this.latestSearch) {
            this.requestDocumentsAndDisplay(this.latestSearch);
        }
    }

    onDocumentOpenInNewWindow(document: Document) {
        if (document) {
            let viewerMode = this.config.viewerModeOverride();
            let overlayMode = this.config.overlayModeOverride();

            let uri = this.api.getDocumentUri(document, viewerMode, overlayMode);
            window.open(uri);
        }
    }

    onDocumentOpen(document: Document) {
        if (document) {
            this.currentDocument = document;
        }
    }

    onDocumentViewClose() {
        this.currentDocument = undefined;
    }
 }
