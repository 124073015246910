import { Component } from "@angular/core";

import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

import { LanguageConfigService, LanguageUserPreferenceService } from "../internal";

@Component({
    selector: "obpa-language-selector",
    templateUrl: "./language-selector.component.html",
    providers: [
        LanguageConfigService,
        LanguageUserPreferenceService
    ]
})
export class LanguageSelectorComponent {
    readonly languages = [
        ['en', 'English'],
        ['de', 'German'],
        ['es', 'Spanish'],
        ['fr', 'French'],
        ['it', 'Italian'],
        ['ja', 'Japanese'],
        ['ko', 'Korean'],
        ['nl', 'Dutch'],
        ['pt', 'Portuguese'],
        ['ro', 'Romanian'],
        ['tr', 'Turkish'],
        ['zh', 'Chinese (Simplified)'],
        ["zh-tw", "Chinese (Traditional)"]
    ];

    selectedLanguage: string;

    setLanguage(newLanguage: string) {
        this.updateLanguage(newLanguage);

        this.userPreference.save(newLanguage);
        this.translate.use(newLanguage);
    }

    updateLanguage(newLanguage: string) {
        if (this.selectedLanguage !== newLanguage) {
            this.selectedLanguage = newLanguage;
        }
    }

    constructor(
        private translate: TranslateService,
        private userPreference: LanguageUserPreferenceService
    ) {
        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateLanguage(event.lang);
        });

        // use current selection from service
        if (this.translate.currentLang) {
            this.updateLanguage(this.translate.currentLang);
        } else {
            // default to English
            this.updateLanguage("en");
        }

        // load user preference
        let storedLanguage = this.userPreference.load();
        if (this.languages.some(pair => pair[0] === storedLanguage)) {
            this.setLanguage(storedLanguage);
        }
    }
}
