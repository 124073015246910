import { Keyword } from "../internal";

export class DocType {
    public readonly id: number;
    public readonly name: string;
    
    public isLoaded: boolean;
    public keywords: Keyword[];
    
    constructor(init: {
        id: number,
        name: string
    }) {
        this.id = init.id;
        this.name = init.name;

        this.isLoaded = false;
        this.keywords = [];
    }
}
