<obpa-status [status]="status" error="{{'ERROR: QUERIES'|translate}}" (retry)="onRetry()">

    <div class="form-group">

        <ng-template [ngIf]="showSingleQuery">
            <label id="obpa_query_label">{{"SEARCH TYPE"|translate}}</label>
            <p class="form-control-static" tabindex="0" aria-labelledby="obpa_query_label">{{selection?.name}}</p>
        </ng-template>

        <ng-template [ngIf]="!showSingleQuery">
            <label for="obpa_query">{{"SEARCH TYPE"|translate}}</label>
            <select id="obpa_query" class="form-control" [ngModel]="selection"
                    (ngModelChange)="onSelectionChange($event)">
                <option *ngFor="let query of queries" [ngValue]="query">{{query.name}}</option>
            </select>
        </ng-template>

    </div>

    <div class="help-block" tabindex="0">{{selection?.instructions}}</div>

</obpa-status>