import { Injectable } from "@angular/core";

import { OperatorConstraint, SearchModel,
         SearchConfigService } from "../internal";

import { KeywordResponse } from "../../shared";

@Injectable()
export class SearchModelUtilService {
    constructor(private config: SearchConfigService) {}

    applyConstraints(model: SearchModel) {
        if (model.keywordResponses) {
            SearchModelUtilService.applyConstraints(model.keywordResponses, this.config.operatorConstraints);
        }
    }

    static applyConstraints(keywordResponses: KeywordResponse[], constraints: OperatorConstraint[]) {
        constraints.forEach((constraint, constraintIndex) => {
            // find matching keywords
            let matchedKeywordResponses: KeywordResponse[];
            if (constraint.keywordId) {
                matchedKeywordResponses = keywordResponses.filter(keyword => keyword.id === constraint.keywordId);
            } else if (constraint.keywordName) {
                matchedKeywordResponses = keywordResponses.filter(keyword => keyword.name === constraint.keywordName);
            } else {
                matchedKeywordResponses = [];
            }

            if (matchedKeywordResponses.length > 0 && matchedKeywordResponses.length >= constraint.instance) {
                // set the constraint
                matchedKeywordResponses[constraintIndex].Operator = constraint.operator;
            }
        });
    }
}
