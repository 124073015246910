export class KeywordResponse {
    public readonly id?: number;
    public readonly name?: string;
    public readonly value: string;

    public Operator: KeywordResponseOperator;

    constructor(init: {
        id?: number,
        name?: string,
        value: string,
    }) {
        this.id = init.id;
        this.name = init.name;
        this.value = init.value;
    }

    // for the API
    toJSON(): any {
        return {
            ID: this.id,
            Name: this.name,
            Value: this.value,
            KeywordOperator: apiKeywordResponseOperator(this.Operator)
        };
    }

    public static fromEFormFormat(key: string, value: string): { response: KeywordResponse, instance: number} | undefined {
        // parse into parts, like [ "OBKey__1_1", "_1", "1" ]
        let splitKey = /^OBKey_(.+)_(\d+)$/.exec(key);

        if (splitKey) {
            if (splitKey[1].charAt(0) === "_") {
                // keywordId
                let id: number = +(splitKey[1].substring(1));
                let instance: number = +(splitKey[2]);
                return {
                    response: new KeywordResponse({value, id}),
                    instance: instance
                };
            } else {
                // keywordName
                let name: string = splitKey[1];
                let instance: number = +(splitKey[2]);
                return {
                    response: new KeywordResponse({value, name}),
                    instance: instance
                };
            }
        } else {
            return undefined;
        }
    }
}

export const enum KeywordResponseOperator {
    Equal,
    LessThan,
    LessThanEqual,
    GreaterThan,
    GreaterThanEqual
}

function apiKeywordResponseOperator(operator: KeywordResponseOperator): string {
    switch (operator) {
        case KeywordResponseOperator.LessThan:
            return "<";
        case KeywordResponseOperator.LessThanEqual:
            return "<=";
        case KeywordResponseOperator.GreaterThan:
            return ">";
        case KeywordResponseOperator.GreaterThanEqual:
            return ">=";
        case KeywordResponseOperator.Equal:
        default:
            return "=";
    }
}