import { Subscription } from "rxjs/Subscription";

export class SingleSubscription {

    private currentSubscription?: Subscription;

    store(subscription: Subscription): Subscription {
        this.cancel();
        this.currentSubscription = subscription;
        return subscription;
    }

    cancel() {
        if (this.currentSubscription && !this.currentSubscription.closed) {
            this.currentSubscription.unsubscribe();
        }
        this.currentSubscription = undefined;
    }

}