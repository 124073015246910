import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "obpa-status",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.css"]
})
export class StatusComponent {
    @Input()
    status: StatusComponentStatus;

    @Input()
    keepVisibleWhileLoading: boolean = false;
    
    @Input()
    error: string;
    
    @Output()
    retry = new EventEmitter();
    
    onRetry() {
        this.retry.emit();
    }

    // we need to be able to reference the enum from within the HTML template 
    StatusComponentStatus = StatusComponentStatus;
}

export enum StatusComponentStatus {
    Normal,
    Loading,
    Error
}
