import { Injectable } from "@angular/core";

import { OperatorConstraint,
         DateService } from "../internal";

import { KeywordResponse, KeywordResponseOperator,
         ConfigService, QueryStringService } from "../../shared";

@Injectable()
export class SearchConfigService {
    constructor(private config: ConfigService, private qs: QueryStringService, private dates: DateService) {}

    get queryLimit(): number {
        return this.config.getNumberOrDefault("api.queryLimit", 0);
    }

    get selectedQueryId(): number | undefined {
        let queryId: number | undefined;

        let qsField = this.config.getString("searchPanel.selectedQueryIdQSField");
        if (qsField && this.qs.hasKey(qsField)) {
            queryId = +(this.qs.value(qsField));
        } else {
            queryId = this.config.getNumber("searchPanel.selectedQueryId");
        }
        
        return queryId;
    }

    get showSingleQuery(): boolean {
        return this.config.getBooleanOrDefault("searchPanel.showSingleQuery", false);
    }

    get keywordList(): KeywordResponse[] {
        let keywords: KeywordResponse[] = [];

        if (this.config.getBooleanOrDefault("searchPanel.useQueryStringKeywords", false)) {
            // pull from QS
            for (let key of this.qs.keys) {
                let keywordResponse = KeywordResponse.fromEFormFormat(key, this.qs.value(key));
                if (keywordResponse) { // only if it was in proper format
                    // ignore the specified instance number
                    keywords.push(keywordResponse.response);
                }
            }
        } else {
            // pull from config
            let rawKeywords: any[] = this.config.get("searchPanel.keywordList");
            rawKeywords.forEach(rawKeyword => {
                keywords.push(new KeywordResponse({
                    value: rawKeyword.value,
                    id: rawKeyword.keywordId,
                    name: rawKeyword.keywordName
                }));
            });
        }

        return keywords;
    }

    get ftSearchText(): string | undefined {
        let searchText: string | undefined;

        let qsField = this.config.getString("searchPanel.ftSearchTextQSField");
        if (qsField && this.qs.hasKey(qsField)) {
            searchText = this.qs.value(qsField);
        } else {
            searchText = this.config.getString("searchPanel.ftSearchText");
        }
        
        return searchText;
    }

    get showDocTypes(): boolean {
        return this.config.getBooleanOrDefault("searchPanel.showDocTypes", false);
    }

    get selectedDocTypeId(): number | undefined {
        return this.config.getNumber("searchPanel.selectedDocTypeId");
    }

    get folderId(): string | undefined {
        let folderId: string | undefined;

        let qsField = this.config.getString("searchPanel.folderIdQSField");
        if (qsField && this.qs.hasKey(qsField)) {
            folderId = this.qs.value(qsField);
        } else {
            // could be an encrypted string
            folderId = this.config.getString("searchPanel.folderIdEncrypted");
            if (!folderId)
            {
                // could be a raw number
                let folderIdNum = this.config.getNumber("searchPanel.folderId");
                if (folderIdNum)
                {
                    folderId = "" + folderIdNum;
                }
            }
        }
        
        return folderId;
    }

    get defaultStartDate(): Date | undefined {
        return this.dates.fromConfig(this.config.getStringOrDefault("searchPanel.defaultStartDate", ""));
    }

    get defaultEndDate(): Date | undefined {
        return this.dates.fromConfig(this.config.getStringOrDefault("searchPanel.defaultEndDate", ""));
    }

    get operatorConstraints(): OperatorConstraint[] {
        let constraints: OperatorConstraint[] = [];

        let rawConstraints: any[] = this.config.get("searchPanel.operatorConstraints");
        rawConstraints.forEach(rawConstraint => {
            let instance = +rawConstraint.instance - 1;

            let operator: KeywordResponseOperator;
            switch (rawConstraint.operator) {
                case "LessThan":
                case "<":
                    operator = KeywordResponseOperator.LessThan;
                    break;
                case "LessThanEqual":
                case "<=":
                    operator = KeywordResponseOperator.LessThanEqual;
                    break;
                case "GreaterThan":
                case ">":
                    operator = KeywordResponseOperator.GreaterThan;
                    break;
                case "GreaterThanEqual":
                case ">=":
                    operator = KeywordResponseOperator.GreaterThanEqual;
                    break;
                case "Equal":
                case "=":
                default:
                    operator = KeywordResponseOperator.Equal;
                    break;
            }

            let keywordId = +rawConstraint.keywordId;
            let keywordName = rawConstraint.keywordName;

            if (keywordId || keywordName) {
                constraints.push(new OperatorConstraint({
                    keywordId,
                    keywordName,
                    instance,
                    operator
                }));
            }
        });

        return constraints;
    }
}
