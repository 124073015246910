import { ViewerMode } from "../internal";

export class DocumentMetadata {
    public readonly size: number;
    public readonly viewerMode: ViewerMode;
    public readonly isAboveDownloadThreshold: boolean;

    constructor (init: {
        size: number,
        viewerMode: ViewerMode,
        isAboveDownloadThreshold: boolean
    }){
        this.size = init.size;
        this.viewerMode = init.viewerMode;
        this.isAboveDownloadThreshold = init.isAboveDownloadThreshold;
    }
}
