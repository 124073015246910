import { DisplayColumnValue, 
         KeywordDataType } from "../";

export class DisplayColumn {
    public readonly heading: string;
    public readonly dataType: KeywordDataType;

    constructor(init: {
        heading: string,
        dataType?: KeywordDataType
    }) {
        this.heading = init.heading;
        this.dataType = init.dataType || KeywordDataType.Null;
    }
}
