import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../shared/shared.module";

import { SearchPanelComponent, QuerySelectorComponent,
         FulltextSearchPanelComponent, DocTypeSelectorComponent,
         SearchFormComponent, SearchFormFulltextComponent, SearchFormDateComponent, SearchFormKeywordComponent, 
         DatePickerDirective } from "./internal";

// We want this Module's providers scoped to this module and NOT added to AppModule.
// So, set them in the top-level @Component decorator rather than @NgModule.

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        SearchPanelComponent,
        FulltextSearchPanelComponent,
        QuerySelectorComponent,
        DocTypeSelectorComponent,
        SearchFormComponent,
        SearchFormFulltextComponent,
        SearchFormDateComponent,
        SearchFormKeywordComponent,
        DatePickerDirective
    ],
    entryComponents: [
        SearchPanelComponent,
        FulltextSearchPanelComponent
    ]
})
export class SearchPanelModule {}
