import { Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";

import { DocumentMetadata, ViewerMode, OverlayMode } from "../internal";

import { Document, 
         ApiService } from "../../shared";

@Injectable()
export class DocumentHitlistApiService {
    constructor(private api: ApiService) {}
    
    getDocumentUri(document: Document, viewerMode?: ViewerMode, overlayMode?: OverlayMode, forceDownload: boolean = false): string {
        let uri = this.api.getDocumentUri(document.docID);
        
        let separator = "?";
        if (viewerMode) {
            viewerMode = ((viewerMode === ViewerMode.NativeOptional) ? ViewerMode.PDF : viewerMode);
            uri += `${separator}ViewerMode=${ViewerMode[viewerMode]}`;
            separator = "&";
        }
        if (overlayMode) {
            uri += `${separator}OverlayMode=${OverlayMode[overlayMode]}`;
            separator = "&";
        }
        if (forceDownload || viewerMode === ViewerMode.Native) {
            uri += `${separator}ForceDownload=true`;
            separator = "&";
        }
        
        return uri;
    }

    tryDocumentUri(document: Document): Observable<DocumentMetadata> {
        return this.api.tryDocumentUri(document.docID)
            .pipe(
                map(DocumentHitlistApiService.parseDocumentMetadata));
    }

    private static parseDocumentMetadata(rawResponseBody: any): DocumentMetadata {
        return new DocumentMetadata({
            size: rawResponseBody.Size,
            viewerMode: DocumentHitlistApiService.parseViewerMode(rawResponseBody.ViewerMode),
            isAboveDownloadThreshold: rawResponseBody.IsAboveDownloadThreshold
        });
    }

    private static parseViewerMode(rawViewerMode: any): ViewerMode {
        switch (rawViewerMode) {
            case "PDF":
            default:
                return ViewerMode.PDF;
            case "Native":
                return ViewerMode.Native;
            case "NativeOptional":
                return ViewerMode.NativeOptional;
        }
    }
}
