import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

@Injectable()
export class SearchFormService {
    public showErrors: boolean = false;

    ctrlHasError(ctrl: FormControl, specificError?: string) {
        if (specificError) {
            return (ctrl.hasError(specificError) && (this.showErrors || ctrl.touched));
        } else {
            return (ctrl.invalid && (this.showErrors || ctrl.touched));
        }
    }
}