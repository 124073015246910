<div [class.has-error]="searchForm.ctrlHasError(dateFormControl)">

    <label attr.for="obpa_date_{{dateID}}">{{dateTitle}}</label>

    <input id="obpa_date_{{dateID}}" [formControl]="dateFormControl" class="form-control"
        [attr.aria-invalid]="searchForm.ctrlHasError(dateFormControl)"
        attr.aria-describedby="obpa_date_{{dateID}}_err"
        obpaDatePicker />
        
    <span id="obpa_date_{{dateID}}_err">
        <span *ngIf="searchForm.ctrlHasError(dateFormControl, 'invalidDate')" class="help-block">
            {{"FORM: INVALID DATE SHORT"|translate:{format:"mm/dd/yyyy"} }}
        </span>
    </span>

</div>