import { Observable } from "rxjs/Observable";
import { of } from "rxjs"

import { TranslateLoader } from "@ngx-translate/core";

type LanguageObject = { [key: string]: string };
const LANGUAGES: [string, LanguageObject][] = [
    ['en', require("./json/en.json")],
    ['de', require("./json/de.json")],
    ['es', require("./json/es.json")],
    ['fr', require("./json/fr.json")],
    ['it', require("./json/it.json")],
    ['ja', require("./json/ja.json")],
    ['ko', require("./json/ko.json")],
    ['nl', require("./json/nl.json")],
    ['pt', require("./json/pt.json")],
    ['ro', require("./json/ro.json")],
    ['tr', require("./json/tr.json")],
    ['zh', require("./json/zh.json")],
    ['zh-tw', require("./json/zh-tw.json")]
];

export class BundledTranslateLoader implements TranslateLoader {
    public static isSupported(lang: string): boolean {
        return LANGUAGES.some(pair => pair[0] == lang);
    }

    public getTranslation(lang: string): Observable<any> {
        let match = LANGUAGES.find(pair => pair[0] == lang);
        if (match) {
            return of(match[1]);
        } else {
            return Observable.throw(new Error("Invalid language key."));
        }
    }
}
