import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
    name: "trustResourceUrl"
})
export class TrustResourceUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(input: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(input);
    }
}
