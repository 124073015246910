export class CustomQueryDates {
    public readonly optionType: CustomQueryDatesOptionType;
    public readonly defaultFrom?: Date;
    public readonly defaultTo?: Date;

    constructor(init: {
        optionType?: CustomQueryDatesOptionType,
        defaultFrom?: Date,
        defaultTo?: Date
    }) {
        this.optionType = init.optionType || CustomQueryDatesOptionType.NoDate;
        this.defaultFrom = init.defaultFrom;
        this.defaultTo = init.defaultTo;
    }
}

export const enum CustomQueryDatesOptionType {
    NoDate = 1,
    SingleDate = 2,
    ToFromDate = 3
}
