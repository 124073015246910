import { Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";
import { map } from 'rxjs/operators/map'

import { CustomQuery, CustomQueryDates, CustomQueryDatesOptionType, CustomQueryRequirements, CustomQueryType, DocType,
    Keyword, KeywordMask, SearchModel,
    SearchConfigService } from "../internal";

import { DisplayColumn, DisplayColumnValue, Document, DocumentList,
    KeywordDataType,
    ApiService } from "../../shared";

@Injectable()
export class SearchApiService {
    constructor(private api: ApiService, private config: SearchConfigService) {}

    getCustomQueries(): Observable<CustomQuery[]> {
        return this.api.requestCustomQueries().pipe(
            map(SearchApiService.parseRawArray),
            map(rawArray => rawArray.map(SearchApiService.parseCustomQuery, this)),
            map(parsedArray => parsedArray.filter(SearchApiService.isCustomQuerySupported, this)))
    }

    getDocTypes(): Observable<DocType[]> {
        return this.api.requestDocTypes().pipe(
            map(SearchApiService.parseRawArray),
            map(rawArray => rawArray.map(SearchApiService.parseDocType, this)))
    }

    getKeywordsByCustomQuery(queryId: number): Observable<Keyword[]> {
        return this.api.requestKeywords({
            QueryID: queryId
        }).pipe(
            map(SearchApiService.parseRawArray),
            map(rawArray => rawArray.map(SearchApiService.parseKeyword, this)))
    }

    getKeywordsByDocType(doctypeId: number): Observable<Keyword[]> {
        return this.api.requestKeywords({
            DocTypeID: doctypeId
        }).pipe(
            map(SearchApiService.parseRawArray),
            map(rawArray => rawArray.map(SearchApiService.parseKeyword, this)))
    }

    getDocumentsByCustomQuery(searchModel: SearchModel, queryId: number): Observable<DocumentList> {
        return this.api.requestDocumentsByKeyword({
            QueryID: queryId,
            Keywords: searchModel.keywordResponses,
            FromDate: searchModel.fromDate,
            ToDate: searchModel.toDate,
            QueryLimit: this.config.queryLimit
        }).pipe(
            map(SearchApiService.parseDocumentList))
    }

    getDocumentsByCustomQueryFullText(searchModel: SearchModel, queryId: number): Observable<DocumentList> {
        return this.api.requestDocumentsByFullText({
            SearchText: searchModel.fullText,
            FromDate: searchModel.fromDate,
            ToDate: searchModel.toDate,
            QueryID: queryId,
            Keywords: searchModel.keywordResponses,
            QueryLimit: this.config.queryLimit
        }).pipe(
            map(SearchApiService.parseDocumentList))
    }

    getDocumentsByFullText(searchModel: SearchModel, doctypeId?: number): Observable<DocumentList> {
        return this.api.requestDocumentsByFullText({
            SearchText: searchModel.fullText,
            FromDate: searchModel.fromDate,
            ToDate: searchModel.toDate,
            DocTypeID: doctypeId,
            Keywords: searchModel.keywordResponses,
            QueryLimit: this.config.queryLimit
        }).pipe(
            map(SearchApiService.parseDocumentList))
    }

    getDocumentsByFolder(folderId: any): Observable<DocumentList> {
        return this.api.requestDocumentsByKeyword({
            FolderID: folderId,
            QueryLimit: this.config.queryLimit
        }).pipe(
            map(SearchApiService.parseDocumentList))
    }

    private static parseRawArray(rawResponseBody: any): any[] {
        return rawResponseBody.Data || [];
    }

    private static parseDocumentList(rawResponseBody: any): DocumentList {
        let documents = SearchApiService.parseRawArray(rawResponseBody)
            .map(SearchApiService.parseDocument);

        let displayColumns: DisplayColumn[] | undefined;
        if (rawResponseBody.DisplayColumns) {
            let rawDisplayColumns: any[] = rawResponseBody.DisplayColumns || [];
            displayColumns = rawDisplayColumns
                .map(SearchApiService.parseDisplayColumn);
        }

        let isTruncated: boolean = false;
        if (rawResponseBody.Truncated) {
            isTruncated = true;
        }

        return new DocumentList({
            documents: documents,
            displayColumns: displayColumns,
            isTruncated: isTruncated
        });
    }

    private static parseCustomQuery(rawCustomQuery: any): CustomQuery {
        let result: CustomQuery = new CustomQuery({
            id: +rawCustomQuery.ID,
            name: rawCustomQuery.Name,
            instructions: rawCustomQuery.Instructions,
            type: SearchApiService.parseCustomQueryType(rawCustomQuery),
            dates: SearchApiService.parseCustomQueryDates(rawCustomQuery),
            requirements: SearchApiService.parseCustomQueryRequirements(rawCustomQuery)
        });

        return result;
    }

    private static parseCustomQueryType(rawCustomQuery: any): CustomQueryType {
        switch (rawCustomQuery.Type) {
            case "DocumentType":
                return CustomQueryType.DocumentType;
            case "DocumentTypeGroup":
                return CustomQueryType.DocumentTypeGroup;
            case "Keyword":
                return CustomQueryType.Keyword;
            case "Custom":
                return CustomQueryType.Custom;
            case "HTMLDocumentType":
                return CustomQueryType.HTMLDocumentType;
            case "HTMLDocumentTypeGroup":
                return CustomQueryType.HTMLDocumentTypeGroup;
            case "HTMLKeyword":
                return CustomQueryType.HTMLKeyword;
            case "FolderType":
                return CustomQueryType.FolderType;
            case "HTMLFolderType":
                return CustomQueryType.HTMLFolderType;
            case "FullText":
                return CustomQueryType.FullText;
            case "HTMLFullText":
                return CustomQueryType.HTMLFullText;
            default:
                return CustomQueryType.None;
        }
    }

    private static parseCustomQueryDates(rawCustomQuery: any): CustomQueryDates {
        let optionType: CustomQueryDatesOptionType;
        let defaultFrom: Date | undefined;
        let defaultTo: Date | undefined;

        switch (rawCustomQuery.DateSearchOption) {
            case "SingleDate":
                optionType = CustomQueryDatesOptionType.SingleDate;
                break;
            case "ToFromDate":
                optionType = CustomQueryDatesOptionType.ToFromDate;
                break;
            // case "NoDate":
            default:
                optionType = CustomQueryDatesOptionType.NoDate;
                break;
        }

        if (optionType !== CustomQueryDatesOptionType.NoDate) {
            if (rawCustomQuery.DateDefaultFrom) {
                defaultFrom = new Date(Date.parse(rawCustomQuery.DateDefaultFrom));
            }
            if (rawCustomQuery.DateDefaultTo) {
                defaultTo = new Date(Date.parse(rawCustomQuery.DateDefaultTo));
            }
        }

        return new CustomQueryDates({
            optionType: optionType,
            defaultFrom: defaultFrom,
            defaultTo: defaultTo
        });
    }

    private static parseCustomQueryRequirements(rawCustomQuery: any): CustomQueryRequirements {
        return new CustomQueryRequirements({
            requiresDateOrKeyword: rawCustomQuery.RequiresDateOrKeyword,
            requiresKeyword: rawCustomQuery.RequiresKeyword,
            requiresDate: rawCustomQuery.RequiresDate
        });
    }

    private static parseDocType(rawDocType: any): DocType {
        let result: DocType = new DocType({
            id: +rawDocType.ID,
            name: rawDocType.Name
        });

        return result;
    }

    private static parseKeyword(rawKeyword: any): Keyword {
        let result: Keyword = new Keyword({
            id: +rawKeyword.ID,
            name: rawKeyword.Name,
            type: SearchApiService.parseKeywordDataType(rawKeyword.DataType),
            required: rawKeyword.Required,
            maxLength: +rawKeyword.MaxLength,
            dataset: rawKeyword.Dataset,
            mask: SearchApiService.parseKeywordMask(rawKeyword)
        });

        return result;
    }

    private static parseKeywordMask(rawKeyword: any): KeywordMask | undefined {
        if (rawKeyword.IsMasked) {
            return new KeywordMask({
                mask: rawKeyword.Mask,
                maskStatic: rawKeyword.MaskStatic,
                maskFullFieldRequired: rawKeyword.MaskFullFieldRequired
            });
        } else {
            return undefined;
        }
    }

    private static parseDocument(rawDocument: any): Document {
        let displayColumnValues: DisplayColumnValue[] | undefined;
        if (rawDocument.DisplayColumnValues) {
            let rawDisplayColumnValues: any[] = rawDocument.DisplayColumnValues || [];
            displayColumnValues = rawDisplayColumnValues
                .map(SearchApiService.parseDisplayColumnValue);
        }

        let result: Document = new Document({
            docID: rawDocument.ID,
            docName: rawDocument.Name,
            displayColumnValues: displayColumnValues,
            fullTextScore: rawDocument.Score,
            fullTextSummary: rawDocument.Summary
        });

        return result;
    }

    private static parseDisplayColumn(rawDisplayColumn: any): DisplayColumn {
        let result: DisplayColumn = new DisplayColumn({
            heading: rawDisplayColumn.Heading,
            dataType: SearchApiService.parseKeywordDataType(rawDisplayColumn.DataType)
        });

        return result;
    }

    private static parseDisplayColumnValue(rawDisplayColumnValue: any): DisplayColumnValue {
        let result: DisplayColumnValue = new DisplayColumnValue({
            value: rawDisplayColumnValue.Value,
            valueRaw: rawDisplayColumnValue.RawValue
        });

        return result;
    }

    private static isCustomQuerySupported(query: CustomQuery): boolean {
        switch (query.type) {
            case CustomQueryType.DocumentType:
            case CustomQueryType.DocumentTypeGroup:
            case CustomQueryType.Keyword:
            case CustomQueryType.Custom:
            case CustomQueryType.FullText:
                return true;
            default:
                return false;
        }
    }

    private static parseKeywordDataType(rawKeywordDataType: any): KeywordDataType {
        switch (rawKeywordDataType) {
            case "Null":
            default:
                return KeywordDataType.Null;
            case "LargeNumeric":
                return KeywordDataType.LargeNumeric;
            case "AlphaNumeric":
                return KeywordDataType.AlphaNumeric;
            case "Currency":
                return KeywordDataType.Currency;
            case "Date":
                return KeywordDataType.Date;
            case "Float":
                return KeywordDataType.Float;
            case "SmallNumeric":
                return KeywordDataType.SmallNumeric;
            case "DateTime":
                return KeywordDataType.DateTime;
            case "AlphaNumericSingleTable":
                return KeywordDataType.AlphaNumericSingleTable;
            case "SpecificCurrency":
                return KeywordDataType.SpecificCurrency;
            case "AlphaNumericCSInsensitiveSearch":
                return KeywordDataType.AlphaNumericCSInsensitiveSearch;
            case "AlphaNumericSingleTableCSInsensitiveSearch":
                return KeywordDataType.AlphaNumericSingleTableCSInsensitiveSearch;
        }
    }
}
