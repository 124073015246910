import { Injectable } from "@angular/core";

@Injectable()
export class QueryStringService {
    private values:  { [id: string]: string | undefined };

    public get keys(): string[] {
        return Object.keys(this.values);
    }

    public hasKey(key: string): boolean {
        return (key in this.values);
    }

    public value(key: string): string {
        return this.values[key] || "";
    }

    constructor() {
        this.use(window.location.search);
    }

    public use(queryString: string): QueryStringService {
        this.values = QueryStringService.parseQueryString(queryString);
        return this;
    }

    private static parseQueryString(qs: string): { [id: string]: string } {
        let result: { [id: string]: string } = {};

        if (qs.length > 0) {
            // remove ?
            if (qs.charAt(0) === "?") {
                qs = qs.substring(1);
            }

            // split by &
            qs.split("&").forEach(rawPair => {
                // split by =
                let splitPair = rawPair.split("=");
                splitPair[0] = decodeURIComponent(splitPair[0]);
                splitPair[1] = decodeURIComponent(splitPair[1]);

                result[splitPair[0]] = splitPair[1];
            });
        }

        return result;
    }
}
