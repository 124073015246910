import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import {
    CustomQueryDatesOptionType, CustomQueryRequirements, Keyword, KeywordMask,
    SearchFormModel, SearchModel,
    DateService
} from "../internal";

import {
    KeywordResponse,
    KeywordDataType
} from "../../shared";

@Injectable()
export class SearchFormUtilService {
    constructor(private dates: DateService) { }

    public toFormGroup(searchFormModel: SearchFormModel): FormGroup {
        // create form
        let formGroup: { [key: string]: AbstractControl } = {};

        // === create full-text search
        if (searchFormModel.showFulltext) {
            formGroup["fulltext"] = new FormControl("", this.getFulltextValidators());
        }

        // === create date [range]
        if (searchFormModel.dates && searchFormModel.dates.optionType != CustomQueryDatesOptionType.NoDate) {
            let dateGroup: { [key: string]: AbstractControl } = {};

            switch (searchFormModel.dates.optionType) {
                case CustomQueryDatesOptionType.SingleDate:
                    dateGroup["single"] = new FormControl(
                        this.dates.toDisplayString(searchFormModel.dates.defaultFrom),
                        SearchFormUtilService.validatorDate(this.dates)
                    );
                    break;
                case CustomQueryDatesOptionType.ToFromDate:
                    dateGroup["from"] = new FormControl(
                        this.dates.toDisplayString(searchFormModel.dates.defaultFrom),
                        SearchFormUtilService.validatorDate(this.dates)
                    );
                    dateGroup["to"] = new FormControl(
                        this.dates.toDisplayString(searchFormModel.dates.defaultTo),
                        SearchFormUtilService.validatorDate(this.dates)
                    );
                    break;
            }

            formGroup["date"] = new FormGroup(dateGroup);
        }

        // === create keywords
        if (searchFormModel.keywords) {
            let keywordsGroup: { [key: string]: AbstractControl } = {};

            searchFormModel.keywords.forEach(keyword => {
                keywordsGroup[keyword.id] = new FormControl("", this.getKeywordValidators(keyword));
            });

            formGroup["keywords"] = new FormGroup(keywordsGroup);
        }

        return new FormGroup(formGroup, this.getFormValidator(searchFormModel.requirements));
    }

    public toSearchModel(form: any): SearchModel {
        // create model
        let model = new SearchModel();

        // === parse full-text search
        model.fullText = form.fulltext;

        // === parse date [range]
        if (form.date) {
            if (form.date.single) {
                let date = this.dates.fromDisplayString(form.date.single);
                model.fromDate = date;
                model.toDate = date;
            } else if (form.date.from || form.date.to) {
                model.fromDate = this.dates.fromDisplayString(form.date.from);
                model.toDate = this.dates.fromDisplayString(form.date.to);
            }
        }

        // === parse keywords
        if (form.keywords) {
            model.keywordResponses = [];
            for (let keywordID in form.keywords) {
                model.keywordResponses.push(new KeywordResponse({
                    value: form.keywords[keywordID],
                    id: +keywordID
                }));
            }
        }

        return model;
    }

    public normalizeDateRange(form: FormGroup) {
        if (form.value.date && form.value.date.from && form.value.date.to) {
            let fromDate = this.dates.fromDisplayString(form.value.date.from)!;
            let toDate = this.dates.fromDisplayString(form.value.date.to)!;

            if (fromDate > toDate) {
                form.patchValue({
                    date: {
                        from: form.value.date.to,
                        to: form.value.date.from
                    }
                });
            }
        }
    }

    private getFulltextValidators(): ValidatorFn[] {
        return [Validators.required, Validators.minLength(3)];
    }

    private getKeywordValidators(keyword: Keyword): ValidatorFn[] {
        let validators: ValidatorFn[] = [];

        if (keyword.required) {
            validators.push(Validators.required);
        }

        if (!keyword.dataset) {
            if (keyword.mask) {
                validators.push(SearchFormUtilService.validatorKeywordMask(keyword.mask));
            } else {
                switch (keyword.type) {
                    case KeywordDataType.SmallNumeric:
                        validators.push(Validators.pattern(/^[0-9]{1,9}$/));
                        break;
                    case KeywordDataType.LargeNumeric:
                        validators.push(Validators.pattern(/^[0-9]{1,20}$/));
                        break;
                    case KeywordDataType.Date:
                    case KeywordDataType.DateTime:
                        validators.push(SearchFormUtilService.validatorDate(this.dates));
                        break;
                    case KeywordDataType.Currency:
                    case KeywordDataType.SpecificCurrency:
                        validators.push(Validators.pattern(/^\$?[0-9]*(\.[0-9]{2})?$/));
                        break;
                    case KeywordDataType.Float:
                        validators.push(Validators.pattern(/^[0-9]*(\.[0-9]+)?$/));
                        break;
                    case KeywordDataType.AlphaNumeric:
                    case KeywordDataType.AlphaNumericSingleTable:
                    case KeywordDataType.AlphaNumericCSInsensitiveSearch:
                    case KeywordDataType.AlphaNumericSingleTableCSInsensitiveSearch:
                        if (keyword.maxLength > 0) {
                            validators.push(Validators.maxLength(keyword.maxLength));
                        }
                        break;
                    case KeywordDataType.Null:
                    default:
                        break;
                }
            }
        }

        return validators;
    }

    private getFormValidator(requirements: CustomQueryRequirements): ValidatorFn {
        // for whatever reason, FormGroup only accepts a single ValidatorFn (not an array)
        return SearchFormUtilService.validatorQueryRequirements(requirements);
    }

    private static validatorDate(dateService: DateService): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let value = control.value;

            if (value !== "" && !dateService.validateDateString(value)) {
                return { "invalidDate": true };
            } else {
                return {};
            }
        }
    }

    private static validatorKeywordMask(keywordMask: KeywordMask): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let value = control.value;

            if (value !== "" && !keywordMask.validateInput(value)) {
                return { "keywordMask": true };
            } else {
                return {};
            }
        };
    }

    private static validatorQueryRequirements(requirements: CustomQueryRequirements): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!requirements || !requirements.hasRequirements) {
                return {};
            }

            let hasKeyword: boolean = false;
            let hasDate: boolean = false;

            // check keywords
            if ("keywords" in control.value) {
                for (let keywordId in control.value.keywords) {
                    if (control.value.keywords[keywordId]) {
                        hasKeyword = true;
                        break;
                    }
                }
            }

            // check dates
            if ("date" in control.value) {
                if (control.value.date["single"]) {
                    hasDate = true;
                } else if (control.value.date["from"] || control.value.date["to"]) {
                    hasDate = true;
                }
            }

            // compare to requirements
            if (requirements.requiresDateOrKeyword) {
                if (!(hasDate || hasKeyword)) {
                    return { "noDateOrKeyword": true };
                }
            } else {
                if (requirements.requiresDate && requirements.requiresKeyword) {
                    if (!(hasDate && hasKeyword)) {
                        return { "noDateAndKeyword": true };
                    }
                } else if (requirements.requiresDate) {
                    if (!hasDate) {
                        return { "noDate": true };
                    }
                } else if (requirements.requiresKeyword) {
                    if (!hasKeyword) {
                        return { "noKeyword": true };
                    }
                }
            }

            return {};
        }
    }
}
