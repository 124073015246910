import { Component, OnInit } from "@angular/core";

import { CustomQuery, CustomQueryType, SearchFormModel, SearchModel,
         DateService, SearchApiService, SearchConfigService, SearchModelUtilService } from "../internal";

import { StatusComponentStatus, SingleSubscription,
	     MessengerService } from "../../shared";

@Component({
    selector: "obpa-search-panel",
    templateUrl: "./search-panel.component.html",
    providers: [
        DateService,
        SearchApiService,
        SearchConfigService,
        SearchModelUtilService
    ]
})
export class SearchPanelComponent implements OnInit {
    status: StatusComponentStatus = StatusComponentStatus.Normal;
    subscription: SingleSubscription = new SingleSubscription();

    isHidden: boolean = false;

    constructor(
        private api: SearchApiService, 
        private config: SearchConfigService,
        private messenger: MessengerService,
        private searchModelUtil: SearchModelUtilService
    ) {}

    ngOnInit() {
        if (this.performPreconfiguredSearch()) {
            // preconfigured search
            this.isHidden = true;
        } else {
            // manual search
        }
    }
    
    // === Query selection / loading
    selectedQuery: CustomQuery;

    onSelectedQueryChange(newSelectedQuery: CustomQuery) {
        this.subscription.cancel();
        
        if (!newSelectedQuery.isLoaded) {
            this.loadKeywordsAndDisplay(newSelectedQuery);
        } else {
            // display form
            this.searchFormModel = this.searchFormModelFromCustomQuery(newSelectedQuery);
            this.status = StatusComponentStatus.Normal;
        }
    }

    onRetry() {
        this.loadKeywordsAndDisplay(this.selectedQuery);
    }

    loadKeywordsAndDisplay(query: CustomQuery) {
        this.status = StatusComponentStatus.Loading;
        
        this.subscription.store(this.api.getKeywordsByCustomQuery(query.id).subscribe(
            keywords => { // success
                query.keywords = keywords;
                query.isLoaded = true;

                // display form
                this.searchFormModel = this.searchFormModelFromCustomQuery(query);
                this.status = StatusComponentStatus.Normal;
            },
            err => { // fail
                console.error(err);
                this.status = StatusComponentStatus.Error;
            }
        ));
    }

    // === Search Form
    searchFormModel: SearchFormModel;

    searchFormModelFromCustomQuery(query: CustomQuery) {
        return new SearchFormModel({
            dateDefaults: query.dates,
            keywords: query.keywords,
            requirements: query.requirements,
            showFulltext: (query.type === CustomQueryType.FullText)
        });
    }

    // === Searches
    performPreconfiguredSearch(): boolean {
        // check if we have a complete search
        let isSingleQuery = this.config.showSingleQuery;
        let singleQuery = this.config.selectedQueryId;
        let keywords = this.config.keywordList;
        let folderId = this.config.folderId;

        if (isSingleQuery && singleQuery && keywords.length > 0) {
            // preconfigured custom query
            let searchModel = new SearchModel({
                fromDate: this.config.defaultStartDate,
                toDate: this.config.defaultEndDate,
                keywordResponses: keywords
            });
            this.searchModelUtil.applyConstraints(searchModel);

            this.messenger.search(this.api.getDocumentsByCustomQuery(searchModel, singleQuery));
            return true;
        } else if (folderId) {
            // preconfigured folder search
            this.messenger.search(this.api.getDocumentsByFolder(folderId));
            return true;
        }

        return false;
    }

    performManualSearch(searchModel: SearchModel) {
        this.searchModelUtil.applyConstraints(searchModel);

        if (this.selectedQuery.type === CustomQueryType.FullText) {
            this.messenger.search(this.api.getDocumentsByCustomQueryFullText(searchModel, this.selectedQuery.id));
        } else {
            this.messenger.search(this.api.getDocumentsByCustomQuery(searchModel, this.selectedQuery.id));
        }
    }
}
