import { CustomQueryDates, CustomQueryRequirements, Keyword } from "../internal";

export class CustomQuery {
    public readonly id: number;
    public readonly name: string;
    public readonly instructions: string;
    public readonly type: CustomQueryType;
    public readonly dates: CustomQueryDates;
    public readonly requirements: CustomQueryRequirements;
    
    public isLoaded: boolean = false;    
    public keywords: Keyword[] = [];

    constructor(init: {
        id: number,
        name: string,
        instructions?: string,
        type?: CustomQueryType,
        dates?: CustomQueryDates,
        requirements?: CustomQueryRequirements
    }) {
        this.id = init.id;
        this.name = init.name;
        this.instructions = init.instructions || "";
        this.type = init.type || CustomQueryType.None;
        this.dates = init.dates || new CustomQueryDates({});
        this.requirements = init.requirements || new CustomQueryRequirements({});

        this.isLoaded = false;
        this.keywords = [];
    }
}

export const enum CustomQueryType {
    None,
    DocumentType,
    DocumentTypeGroup,
    Keyword,
    Custom,
    HTMLDocumentType,
    HTMLDocumentTypeGroup,
    HTMLKeyword,
    FolderType,
    HTMLFolderType,
    FullText,
    HTMLFullText
}
