export class DisplayColumnValue {
    public readonly value: string;
    public readonly raw: string | undefined;

    constructor(init: {
        value: string,
        valueRaw?: string
    }) {
        this.value = init.value;
        this.raw = init.valueRaw
    }
}
