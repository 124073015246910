import { KeywordResponse } from "../../shared";

export class SearchModel {
    public fullText?: string;
    public fromDate?: Date;
    public toDate?: Date;
    public keywordResponses?: KeywordResponse[];

    constructor(init: {
        fullText?: string,
        fromDate?: Date,
        toDate?: Date,
        keywordResponses?: KeywordResponse[]
    } = {}) {
        this.fullText = init.fullText;
        this.fromDate = init.fromDate;
        this.toDate = init.toDate;
        this.keywordResponses = init.keywordResponses;
    }
}
