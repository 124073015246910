type MaskArray = (string|RegExp)[];

export class KeywordMask {
    public readonly textMaskConfig: any;
    public readonly helpText: string;

    private readonly mask: string;
    private readonly maskStatic: string;
    private readonly maskFullFieldRequired: boolean;

    constructor(init: {
        mask: string,
        maskStatic: string,
        maskFullFieldRequired?: boolean
    }) {
        this.mask = init.mask;
        this.maskStatic = init.maskStatic;
        this.maskFullFieldRequired = init.maskFullFieldRequired || false;

        // https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
        this.textMaskConfig = {
            mask: this.buildMaskArray(),
            guide: false
        }
        this.helpText = this.buildHelpText();
    }

    public validateInput(input: string): boolean {
        let result: boolean = true;

        let inputLength = input.length;
        let maskLength = this.mask.length;
        
        if (inputLength > maskLength) {
            return false;
        }

        if (this.maskFullFieldRequired && inputLength !== maskLength) {
            return false;
        }

        for (let i = 0; i < inputLength; i++) {
            let inputChar = input.charAt(i);
            let maskChar = this.mask.charAt(i);

            switch (maskChar) {
                case "A":
                case "0":
                case "9":
                case "X":
                    if (!REGEXP[maskChar].test(inputChar)) {
                        return false;
                    }
                    break;
                case "S":
                    if (inputChar !== this.maskStatic.charAt(i)) {
                        return false;
                    }
                    break;
                default:
                    KeywordMask.throwInvalidMask(maskChar);
            }
        }

        return true;
    }

    public unmaskInput(input: string): string {
        let result: string = "";

        for (let i = 0, len = Math.min(input.length, this.mask.length); i < len; i++) {
            if (this.mask.charAt(i) !== "S") {
                result += input.charAt(i);
            }
        }

        return result;
    }

    private buildHelpText(): string {
        let result: string = "";

        for (let i = 0, len = this.mask.length; i < len; i++) {
            let maskChar = this.mask.charAt(i);
            switch (maskChar) {
                case "A":
                case "0":
                case "9":
                case "X":
                    result += maskChar;
                    break;
                case "S":
                    result += this.maskStatic.charAt(i);
                    break;
                default:
                    KeywordMask.throwInvalidMask(maskChar);
            }
        }

        return result;
    }

    private buildMaskArray(): MaskArray {
        let result: MaskArray = [];

        for (let i = 0, len = this.mask.length; i < len; i++) {
            let maskChar = this.mask.charAt(i);
            switch (maskChar) {
                case "A":
                case "0":
                case "9":
                case "X":
                    result.push(REGEXP[maskChar]);
                    break;
                case "S":
                    result.push(this.maskStatic.charAt(i));
                    break;
                default:
                    KeywordMask.throwInvalidMask(maskChar);
            }
        }

        return result;
    }

    private static throwInvalidMask(maskChar: string) {
        throw new Error(`Invalid KeywordMask character : '${maskChar}' .`);
    }
}

// see OnBase: Hyland.Controls.Web\KeywordPanel\Scripts\KeywordPanelItem.js
const REGEXP: { [key: string]: RegExp } = {
    "A": /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]$/,
    "0": /^[\d]$/,
    "9": /^[\d+-.*]$/,
    "X": /^[\s\S*]$/
}
