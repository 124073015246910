import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';

@Injectable()
export class ConfigService {
    private config: any = {};

    constructor(private http: HttpClient) { }

    load(): any {
        // load config from the current directory
        return this.http.get("./obpa-config.json").pipe(
            map(response => this.use(response)),
            catchError(() => throwError("Unable to retrieve configuration."))
        );
    }

    use(configObject: any): ConfigService {
        this.config = configObject;
        return this;
    }

    get(key: string): any {
        return this.getProperty(key);
    }

    getString(key: string): string | undefined {
        let val = this.getProperty(key);
        return ((typeof val === "string") ? val : undefined);
    }

    getStringOrDefault(key: string, def: string): string {
        let val = this.getString(key);
        return ((val !== undefined) ? val : def);
    }

    getBoolean(key: string): boolean | undefined {
        let val = this.getProperty(key);
        return ((typeof val === "boolean") ? val : undefined);
    }

    getBooleanOrDefault(key: string, def: boolean): boolean {
        let val = this.getBoolean(key);
        return ((val !== undefined) ? val : def);
    }

    getNumber(key: string): number | undefined {
        let val = this.getProperty(key);
        return ((typeof val === "number") ? val : undefined);
    }

    getNumberOrDefault(key: string, def: number): number {
        let val = this.getNumber(key);
        return ((val !== undefined) ? val : def);
    }

    private getProperty(key: string): any {
        let splitKey = key.split(".");

        return splitKey.reduce((prev: any, curr: string) => {
            return prev ? prev[curr] : undefined;
        }, this.config);
    }
}
