export class CustomQueryRequirements {
    public readonly hasRequirements: boolean;

    public readonly requiresDateOrKeyword: boolean;
    public readonly requiresKeyword: boolean;
    public readonly requiresDate: boolean;

    constructor(init: {
        requiresDateOrKeyword?: boolean,
        requiresKeyword?: boolean,
        requiresDate?: boolean
    }) {
        this.requiresDateOrKeyword = init.requiresDateOrKeyword || false;
        this.requiresKeyword = init.requiresKeyword || false;
        this.requiresDate = init.requiresDate || false;

        this.hasRequirements = this.requiresKeyword || this.requiresDate || this.requiresDateOrKeyword;
    }
}
