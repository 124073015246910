import { Component, OnInit } from "@angular/core";

import { CustomQueryDates, CustomQueryDatesOptionType, DocType, SearchFormModel, SearchModel,
         DateService, SearchApiService, SearchConfigService, SearchModelUtilService } from "../internal";

import { StatusComponentStatus, SingleSubscription,
         MessengerService } from "../../shared";

// always show From/To date for FT searches
const DATE_DEFAULTS = new CustomQueryDates({
    optionType: CustomQueryDatesOptionType.ToFromDate
});

@Component({
    selector: "obpa-fulltext-search-panel",
    templateUrl: "./fulltext-search-panel.component.html",
    providers: [
        DateService,
        SearchApiService,
        SearchConfigService,
        SearchModelUtilService
    ]
})
export class FulltextSearchPanelComponent implements OnInit {
    status: StatusComponentStatus = StatusComponentStatus.Normal;
    subscription: SingleSubscription = new SingleSubscription();

    isHidden: boolean = false;
    showDoctypes: boolean;
    
    constructor(
        private api: SearchApiService, 
        private config: SearchConfigService,
        private messenger: MessengerService,
        private searchModelUtil: SearchModelUtilService
    ) {}

    ngOnInit() {
        if (this.performPreconfiguredSearch()) {
            // preconfigured search
            this.isHidden = true;
        } else {
            // normal search
            if (this.config.showDocTypes) {
                this.showDoctypes = true;
            } else {
                this.showDoctypes = false;
                this.searchFormModel = new SearchFormModel({
                    showFulltext: true,
                    dateDefaults: DATE_DEFAULTS
                });
            }
        }
    }

    // === DocType selection / loading
    selectedDoctype: DocType;

    onSelectedDoctypeChange(newSelectedDoctype: DocType) {
        this.subscription.cancel();
        
        if (!newSelectedDoctype.isLoaded) {
            this.loadKeywordsAndDisplay(newSelectedDoctype);
        } else {
            // display form
            this.searchFormModel = this.searchFormModelFromDocType(newSelectedDoctype);
            this.status = StatusComponentStatus.Normal;
        }
    }

    onRetry() {
        this.loadKeywordsAndDisplay(this.selectedDoctype);
    }

    loadKeywordsAndDisplay(doctype: DocType) {
        this.status = StatusComponentStatus.Loading;
        
        this.subscription.store(this.api.getKeywordsByDocType(doctype.id).subscribe(
            keywords => { // success
                doctype.keywords = keywords;
                doctype.isLoaded = true;

                // display form
                this.searchFormModel = this.searchFormModelFromDocType(doctype);
                this.status = StatusComponentStatus.Normal;
            },
            err => { // fail
                console.error(err);
                this.status = StatusComponentStatus.Error;
            }
        ));
    }

    // === Search Form
    searchFormModel: SearchFormModel;

    searchFormModelFromDocType(doctype: DocType): SearchFormModel {
        return new SearchFormModel({
            showFulltext: true,
            dateDefaults: DATE_DEFAULTS,
            keywords: doctype.keywords
        });
    }

    // === Searches
    performPreconfiguredSearch(): boolean {
        // check if we have a complete search
        let searchText = this.config.ftSearchText;
        
        if (searchText) {
            // preconfigured full-text search
            let searchModel = new SearchModel({
                fullText: searchText
            });

            this.messenger.search(this.api.getDocumentsByFullText(searchModel));
            return true;
        }

        return false;
    }

    performManualSearch(searchModel: SearchModel) {
        let doctypeId = this.selectedDoctype ? this.selectedDoctype.id : undefined;

        this.searchModelUtil.applyConstraints(searchModel);
        this.messenger.search(this.api.getDocumentsByFullText(searchModel, doctypeId));
    }
}
