import { KeywordMask } from "../internal";

import { KeywordDataType } from "../../shared";

export class Keyword {
    public readonly id: number;
    public readonly name: string;
    public readonly type: KeywordDataType;
    public readonly required: boolean;
    public readonly maxLength: number;
    public readonly dataset?: string[];
    public readonly mask?: KeywordMask;

    constructor(init: {
        id: number,
        name: string,
        type?: KeywordDataType,
        required?: boolean,
        maxLength?: number,
        dataset?: string[],
        mask?: KeywordMask
    }) {
        this.id = init.id;
        this.name = init.name;
        this.type = init.type || KeywordDataType.Null;
        this.required = init.required || false;
        this.maxLength = init.maxLength || 0;
        this.dataset = init.dataset;
        this.mask = init.mask;
    }
}

