import { AfterViewInit, Directive, ElementRef, OnDestroy } from "@angular/core";
import { NgControl } from "@angular/forms";

import { DateService } from "../internal";

@Directive({
    selector: "input[obpaDatePicker]"
})
export class DatePickerDirective implements AfterViewInit, OnDestroy {
    constructor(
        private dateElementRef: ElementRef,
        private model: NgControl,
        private dates: DateService
    ) { }

    dateElement: JQuery;

    ngAfterViewInit() {
        this.dateElement = $(this.dateElementRef.nativeElement).datepicker({
            dateFormat: "",

            // update the model
            onSelect: (dateText: string, inst: any) => {
                let selectionDate = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
                this.model.control!.setValue(this.dates.toDisplayString(selectionDate));
            },

            // blur
            onClose: (dateText: string, inst: any) => {
                this.model.control!.markAsTouched();
            }
        })
    }

    ngOnDestroy() {
        if (this.dateElement) {
            this.dateElement.datepicker("destroy");
        }
    }
}
