import { ApplicationRef, ComponentFactoryResolver, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { SharedModule } from "./shared";
import { SearchPanelModule, SearchPanelComponent, FulltextSearchPanelComponent } from "./search-panel";
import { DocumentHitlistModule, DocumentHitlistComponent } from "./document-hitlist";
import { LanguageSelectorModule, LanguageSelectorComponent } from "./language-selector";

// These HTML-level OBPA components should be listed in @NgModule.entryComponents[],
// in either AppModule or an imported NgModule.
const BOOTSTRAPPED_COMPONENTS: any[] = [
    LanguageSelectorComponent,
    SearchPanelComponent,
    FulltextSearchPanelComponent,
    DocumentHitlistComponent
];

@NgModule({
    imports: [
        BrowserModule,
        SharedModule.forRoot(),
        SearchPanelModule,
        DocumentHitlistModule,
        LanguageSelectorModule
    ],
    declarations: [
        // global components, directives, pipes
    ],
    providers: [
        // global injected services
    ]
})
export class AppModule {
    constructor(private cfr: ComponentFactoryResolver) {}

    // Automatic bootstrap (NgModule.bootstrap[]) WILL FAIL if the selector is not found in the DOM.
    // Perform a manual bootstrap of components, by first checking for the selector's existence.

    ngDoBootstrap(appRef: ApplicationRef) {
        BOOTSTRAPPED_COMPONENTS.map(component => this.cfr.resolveComponentFactory(component)).forEach(componentFactory => {
            // attempt to bootstrap if the element exists
            if (document.querySelector(componentFactory.selector)) {
                appRef.bootstrap(componentFactory);
            }
        });
    }
}
