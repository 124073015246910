import { Injectable } from "@angular/core";

import { ConfigService } from "../../shared";

@Injectable()
export class LanguageConfigService {
    constructor(private config: ConfigService) {}

    rememberUserPreference(): boolean {
        return this.config.getBooleanOrDefault("language.rememberUserPreference", true);
    }
}
