import { Injectable } from "@angular/core";

import { LanguageConfigService } from "../internal";

const IDENTIFIER = "languageUserPreference";

@Injectable()
export class LanguageUserPreferenceService {
    constructor(private config: LanguageConfigService) {}

    save(iso: string): void {
        if (this.config.rememberUserPreference()) {
            localStorage.setItem(IDENTIFIER, iso);
        }
    }

    load(): string {
        let result: string | undefined;

        if (this.config.rememberUserPreference()) {
            let saved = localStorage.getItem(IDENTIFIER);
            if (saved) {
                result = saved;
            }
        }

        return result || "";
    }

    clear(): void {
        localStorage.removeItem(IDENTIFIER);
    }
}
