import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { SharedModule } from "../shared";

import { LanguageSelectorComponent } from "./internal";

// We want this Module's providers scoped to this module and NOT added to AppModule.
// So, set them in the top-level @Component decorator rather than @NgModule.

@NgModule({
    imports: [
        SharedModule,
        FormsModule
    ],
    declarations: [
        LanguageSelectorComponent
    ],
    entryComponents: [
        LanguageSelectorComponent
    ]
})
export class LanguageSelectorModule {}
