export enum ViewerMode {
    PDF,
    Native,
    NativeOptional
}

export enum OverlayMode {
    Off,
    View,
    Print
}
