<div [ngSwitch]="keywordSimpleType" [class.has-error]="searchForm.ctrlHasError(keywordFormControl)">

    <label attr.for="obpa_kw_{{keyword.id}}">{{keyword.name}}</label>

    <ng-template ngSwitchCase="Dataset">
        <input attr.list="obpa_kw_{{keyword.id}}_list"
               class="form-control"
               [formControl]="keywordFormControl"
               name="obpa_kw_{{keyword.id}}"
               id="obpa_kw_{{keyword.id}}"
               [attr.aria-required]="keyword.required"
               [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err_common">
            <datalist id="obpa_kw_{{keyword.id}}_list">
                <option value=""></option>
                <option *ngFor="let datum of keyword.dataset" [value]="datum">{{datum}}</option>
            </datalist>        
    </ng-template>

    <ng-template ngSwitchCase="Mask">
        <input id="obpa_kw_{{keyword.id}}" [formControl]="keywordFormControl" class="form-control"
               [attr.aria-required]="keyword.required" [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err obpa_kw_{{keyword.id}}_err_common"
               [textMask]="keyword.mask.textMaskConfig" />
        <span id="obpa_kw_{{keyword.id}}_err">
            <span *ngIf="!searchForm.ctrlHasError(keywordFormControl, 'required')" class="help-block">
                ({{keyword.mask.helpText}})
            </span>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="SmallNumeric">
        <input id="obpa_kw_{{keyword.id}}" [formControl]="keywordFormControl" class="form-control"
               [attr.aria-required]="keyword.required" [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err obpa_kw_{{keyword.id}}_err_common" />
        <span id="obpa_kw_{{keyword.id}}_err">
            <span *ngIf="searchForm.ctrlHasError(keywordFormControl, 'pattern')" class="help-block">
                {{"FORM: INVALID NUMERIC"|translate:{digits:9} }}
            </span>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="LargeNumeric">
        <input id="obpa_kw_{{keyword.id}}" [formControl]="keywordFormControl" class="form-control"
               [attr.aria-required]="keyword.required" [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err obpa_kw_{{keyword.id}}_err_common" />
        <span id="obpa_kw_{{keyword.id}}_err">
            <span *ngIf="searchForm.ctrlHasError(keywordFormControl, 'pattern')" class="help-block">
                {{"FORM: INVALID NUMERIC"|translate:{digits:20} }}
            </span>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="Date">
        <input id="obpa_kw_{{keyword.id}}" [formControl]="keywordFormControl" class="form-control"
               [attr.aria-required]="keyword.required" [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err obpa_kw_{{keyword.id}}_err_common" obpaDatePicker />
        <span id="obpa_kw_{{keyword.id}}_err">
            <span *ngIf="searchForm.ctrlHasError(keywordFormControl, 'invalidDate')" class="help-block">
                {{"FORM: INVALID DATE"|translate:{format:"mm/dd/yyyy"} }}
            </span>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="Currency">
        <input id="obpa_kw_{{keyword.id}}" [formControl]="keywordFormControl" class="form-control"
               [attr.aria-required]="keyword.required" [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err obpa_kw_{{keyword.id}}_err_common" />
        <span id="obpa_kw_{{keyword.id}}_err">
            <span *ngIf="searchForm.ctrlHasError(keywordFormControl, 'pattern')" class="help-block">
                {{"FORM: INVALID CURRENCY"|translate}}
            </span>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="Float">
        <input id="obpa_kw_{{keyword.id}}" [formControl]="keywordFormControl" class="form-control"
               [attr.aria-required]="keyword.required" [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err obpa_kw_{{keyword.id}}_err_common" />
        <span id="obpa_kw_{{keyword.id}}_err">
            <span *ngIf="searchForm.ctrlHasError(keywordFormControl, 'pattern')" class="help-block">
                {{"FORM: INVALID FLOAT"|translate}}
            </span>
        </span>
    </ng-template>

    <ng-template ngSwitchCase="AlphaNumeric">
        <input id="obpa_kw_{{keyword.id}}" [formControl]="keywordFormControl" class="form-control"
               [attr.aria-required]="keyword.required" [attr.aria-invalid]="searchForm.ctrlHasError(keywordFormControl)"
               attr.aria-describedby="obpa_kw_{{keyword.id}}_err obpa_kw_{{keyword.id}}_err_common" />
        <span id="obpa_kw_{{keyword.id}}_err">
            <span *ngIf="searchForm.ctrlHasError(keywordFormControl, 'maxlength')" class="help-block">
                {{"FORM: INVALID LENGTH"|translate:{chars:keyword.maxLength} }}
            </span>
        </span>
    </ng-template>

    <span id="obpa_kw_{{keyword.id}}_err_common">
        <span *ngIf="searchForm.ctrlHasError(keywordFormControl, 'required')" class="help-block">
            {{"FORM: REQUIRED KEYWORD"|translate}}
        </span>
    </span>

</div>