<obpa-status
    [status]="status"
    error="{{'ERROR: DOCTYPES'|translate}}"
    (retry)="onRetry()">
    
    <div class="form-group">

        <label for="obpa_doctype">{{"DOCUMENT TYPE"|translate}}</label>
        <select id="obpa_doctype" class="form-control" [ngModel]="selection" (ngModelChange)="onSelectionChange($event)">
            <option *ngFor="let doctype of doctypes" [ngValue]="doctype">{{doctype.name}}</option>
        </select>

    </div>
    
</obpa-status>
