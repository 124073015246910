<obpa-status [status]="status" [keepVisibleWhileLoading]="true" error="{{'ERROR: DOCUMENTS'|translate}}"
             (retry)="onRetry()">

    <ng-template [ngIf]="documentList">

        <div *ngIf="documentList.isTruncated" class="alert alert-warning" tabindex="0">
            {{"QUERY LIMIT REACHED"|translate}}
        </div>

        <div *ngIf="documentList.documents.length == 0" class="alert alert-info" tabindex="0">
            {{"NO DOCUMENTS FOUND"|translate}}
        </div>

        <obpa-document-hitlist-grid *ngIf="documentList.documents.length > 0" [documents]="documentList.documents"
                                    [columns]="documentList.displayColumns" [isFullText]="documentList.isFullText"
                                    [isMobileBrowser]="isMobileBrowser" (documentOpen)="onDocumentOpen($event)"
                                    (documentOpenInNewWindow)="onDocumentOpenInNewWindow($event)">
        </obpa-document-hitlist-grid>

    </ng-template>

</obpa-status>

<!-- Use [hidden] instead of *ngIf, since IE crashes when a PDF iframe is removed from DOM. -->
<obpa-document-view [attr.data-hidden]="!currentDocument" [document]="currentDocument"
                    [isMobileBrowser]="isMobileBrowser" (close)="onDocumentViewClose()"></obpa-document-view>