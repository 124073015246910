<div class="docview-background" (click)="onClose()">
    <div class="docview-container" (click)="$event.stopPropagation()">
        <div class="docview-panel">
            <div class="docview-panel-header">
                <div class="navbar navbar-default">
                    <div class="navbar-header pull-left">
                        <span class="navbar-brand docview-title" [innerHTML]="document?.docName | trustHtml"></span>
                    </div>
                    <div class="navbar-header pull-right">
                        <button type="button" (click)="onDocumentOpenInNewWindow()" 
                                title="{{'OPEN IN NEW WINDOW'|translate}}" class="btn btn-link navbar-btn">
                            <span class="glyphicon glyphicon-new-window"></span>
                        </button>
                        <button type="button" (click)="onDocumentDownloadNative()" *ngIf="model.showDownloadNative"
                                title="{{'DOWNLOAD NATIVE'|translate}}" class="btn btn-link navbar-btn">
                            <span class="glyphicon glyphicon-download-alt"></span>
                        </button>
                        <button type="button" (click)="onClose()"
                                title="{{'CLOSE'|translate}}" class="btn btn-link navbar-btn">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="docview-panel-body">
                <obpa-loading *ngIf="!model.viewerVisible"></obpa-loading>
                <iframe [attr.data-hidden]="!model.viewerVisible" class="docview-frame" [src]="model.viewerUri | trustResourceUrl"></iframe>
            </div>
        </div>
    </div>
</div>
