// === Angular2
import "core-js/es";
import "core-js/proposals/reflect-metadata";
import "zone.js/dist/zone";

// use a window reference we can edit without TS caring
let tsWindow: any = window as any;

// === IE9 console fix
{
    // In IE9, window.console does not exist until dev tools are opened.
    // Create dummy functions (noop's) as placeholders.
    const noop = () => { };

    if (!window.console) tsWindow.console = {};
    if (!window.console.log) tsWindow.console.log = noop;
    if (!window.console.error) tsWindow.console.error = noop;
    if (!window.console.warn) tsWindow.console.warn = noop;
    if (!window.console.assert) tsWindow.console.assert = noop;
}

// === IE ClassList polyfill
require("script-loader!classlist.js/classlist.min.js");

// === Ignite UI
// jQuery and jQueryUi are external dependencies, should already be on the page.
// common
tsWindow["Class"] = tsWindow["Class"] || function () { };
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.util.js");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.datasource.js");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.templating.js");
// require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.scroll.js");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.shared.js");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.grid.framework.js");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.grid.shared.js");
// require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.popover.js");
// require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.grid.featurechooser.js");
// features
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.grid.sorting.js");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.grid.selection.js");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/js/modules/infragistics.ui.grid.resizing.js");
// styles
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/css/themes/infragistics/infragistics.theme.css");
// require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/css/structure/modules/infragistics.ui.scroll.css");
// require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/css/structure/modules/infragistics.ui.shared.css");
require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/css/structure/modules/infragistics.ui.grid.css");
// require("../../../third-party-assemblies/Infragistics/IgniteUI/16.2.20162.2040/css/structure/modules/infragistics.ui.popover.css");
