import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

import { DocumentHitlistApiService, DocumentHitlistConfigService,
         DocumentMetadata, ViewerMode, OverlayMode } from "../internal";

import { Document, SingleSubscription } from "../../shared";

const BLANK: string = "about:blank";

@Component({
    selector: "obpa-document-view",
    templateUrl: "./document-view.component.html",
    styleUrls: ["./document-view.component.css"]
})
export class DocumentViewComponent {
    subscription: SingleSubscription = new SingleSubscription();

    constructor(
        private api: DocumentHitlistApiService,
        private config: DocumentHitlistConfigService) {}

    @Input()
    isMobileBrowser: boolean = false;
    
    @Input()
    get document(): Document | undefined {
        return this._document;
    }
    set document(doc: Document | undefined) {
        this._document = doc;

        this.model = new DocumentViewModel();
        if (doc) {
            this.documentLoadAndDisplay(doc);
        } else {
            this.subscription.cancel();
        }
    }
    private _document?: Document;

    model: DocumentViewModel = new DocumentViewModel();

    documentLoadAndDisplay(document: Document) {
        // check if the document can be viewed inline
        this.subscription.store(this.api.tryDocumentUri(document).subscribe(
            metadata => {
                let model = new DocumentViewModel();

                // get overridden viewer mode, otherwise use server's mode
                let viewerMode: ViewerMode | undefined = this.config.viewerModeOverride()
                if (!viewerMode) {
                    viewerMode = metadata.viewerMode;
                }
                let overlayMode = this.config.overlayModeOverride();

                // NativeOptional behaves as PDF, but shows download button
                if (viewerMode === ViewerMode.NativeOptional) {
                    model.showDownloadNative = true;
                    viewerMode = ViewerMode.PDF;
                }

                if (viewerMode === ViewerMode.PDF && !metadata.isAboveDownloadThreshold && !this.isMobileBrowser) {
                    // use inline viewer
                    model.viewerUri = this.api.getDocumentUri(document, viewerMode, overlayMode);
                    model.viewerVisible = true;
                    this.model = model;
                } else {
                    // open for download in new window
                    let uri = this.api.getDocumentUri(document, viewerMode, overlayMode, true);

                    this.onClose();
                    window.open(uri);
                }
            },
            error => {
                console.log("Document cannot be opened.");
                this.onClose();
            }
        ));
    }

    @HostListener("document:keydown", ["$event"])
    keydownHandle(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.onClose();
        }
    }

    @Output()
    close = new EventEmitter();

    onDocumentOpenInNewWindow() {
        if (this.document) {
            let viewerMode = this.config.viewerModeOverride();
            let overlayMode = this.config.overlayModeOverride();

            let uri = this.api.getDocumentUri(this.document, viewerMode, overlayMode);
            window.open(uri);
        }
    }

    onDocumentDownloadNative() {
        if (this.document) {
            let viewerMode = ViewerMode.Native;
            let overlayMode = this.config.overlayModeOverride();

            let uri = this.api.getDocumentUri(this.document, viewerMode, overlayMode, true);
            window.open(uri);
        }
    }

    onClose() {
        this.close.emit();
    }
}

class DocumentViewModel {
    viewerVisible: boolean = false;
    viewerUri: string = BLANK;
    showDownloadNative: boolean = false;
}
