import { CustomQueryDates, CustomQueryRequirements, Keyword } from "../internal";

export class SearchFormModel {
    public readonly showFulltext: boolean;
    public readonly dates: CustomQueryDates;
    public readonly keywords?: Keyword[];
    public readonly requirements: CustomQueryRequirements;

    constructor(init: {
        showFulltext?: boolean,
        dateDefaults?: CustomQueryDates,
        keywords?: Keyword[],
        requirements?: CustomQueryRequirements
    }) {
        this.showFulltext = init.showFulltext || false;
        this.dates = init.dateDefaults || new CustomQueryDates({});
        this.keywords = init.keywords;
        this.requirements = init.requirements || new CustomQueryRequirements({});
    }
}
