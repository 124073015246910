import { KeywordResponseOperator } from "../../shared";

export class OperatorConstraint {
    public readonly keywordId?: number;
    public readonly keywordName?: string;

    // 0-based instance number
    public readonly instance: number;

    public readonly operator: KeywordResponseOperator;

    constructor(init: {
        keywordId?: number,
        keywordName?: string,
        instance: number,
        operator: KeywordResponseOperator
    }) {
        this.keywordId = init.keywordId;
        this.keywordName = init.keywordName;
        this.instance = init.instance;
        this.operator = init.operator;
    }
}
