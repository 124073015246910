<div [class.has-error]="searchForm.ctrlHasError(fulltextFormControl)">

    <label for="obpa_fulltext">{{"SEARCH TERM"|translate}}</label>

    <input id="obpa_fulltext" [formControl]="fulltextFormControl" class="form-control"
        [attr.aria-required]="true"
        [attr.aria-invalid]="searchForm.ctrlHasError(fulltextFormControl)"
        aria-describedby="obpa_fulltext_err" />

    <span id="obpa_fulltext_err">
        <span *ngIf="fulltextFormControl.invalid" class="help-block">
            {{"FORM: INVALID FULLTEXT"|translate:{chars:3} }}
        </span>
    </span>
</div>