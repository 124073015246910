import { Injectable } from "@angular/core";

import { ViewerMode, OverlayMode } from "../internal";

import { ConfigService } from "../../shared";

@Injectable()
export class DocumentHitlistConfigService {
    constructor(private config: ConfigService) {}

    autoDisplaySingleDoc(): boolean {
        return this.config.getBooleanOrDefault("docHitList.autoDisplaySingleDoc", false);
    }

    viewerModeOverride(): ViewerMode | undefined {
        let viewerMode = this.config.getString("docHitList.viewerMode");

        switch (viewerMode ? viewerMode.toLowerCase() : undefined) {
            case "pdf":
                return ViewerMode.PDF;
            case "native":
                return ViewerMode.Native;
            case "nativeoptional":
                return ViewerMode.NativeOptional;
            default:
                return undefined;
        }
    }

    overlayModeOverride(): OverlayMode | undefined {
        let overlayMode = this.config.getString("docHitList.overlayMode");

        switch (overlayMode ? overlayMode.toLowerCase() : undefined) {
            case "off":
                return OverlayMode.Off;
            case "print":
                return OverlayMode.Print;
            case "view":
                return OverlayMode.View;
            default:
                return undefined;
        }
    }
}
