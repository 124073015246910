<ng-template [ngIf]="status !== StatusComponentStatus.Error">

    <ng-template [ngIf]="status === StatusComponentStatus.Loading">
        <obpa-loading></obpa-loading>
    </ng-template>

    <ng-template [ngIf]="keepVisibleWhileLoading || status !== StatusComponentStatus.Loading">
        <ng-content></ng-content>
    </ng-template>

</ng-template>

<ng-template [ngIf]="status === StatusComponentStatus.Error">
    <div class="alert alert-danger">
        <strong>{{"ERROR"|translate}}</strong>
        {{error}}
        <a href="#" (click)="onRetry()" class="alert-link">{{"RETRY"|translate}}</a>
    </div>
</ng-template>