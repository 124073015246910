import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { DocType,
         SearchApiService, SearchConfigService } from "../internal";

import { StatusComponentStatus } from "../../shared";

@Component({
    selector: "obpa-doctype-selector",
    templateUrl: "./doctype-selector.component.html"
})
export class DocTypeSelectorComponent implements OnInit {
    status: StatusComponentStatus = StatusComponentStatus.Loading;

    @Input()
    selection: DocType;
    
    @Output()
    selectionChange = new EventEmitter();
    
    doctypes: DocType[] = [];
    initialSelectedDoctypeId?: number;
    
    constructor(
        private api: SearchApiService, 
        private config: SearchConfigService
    ) {
        this.initialSelectedDoctypeId = config.selectedDocTypeId;
    }
    
    ngOnInit() {
        this.loadDoctypes();
    }
    
    loadDoctypes() {
        this.status = StatusComponentStatus.Loading;
        this.api.getDocTypes().subscribe(
            doctypes => { // success
                this.doctypes = doctypes;
                
                // select initial query, if it is specified and valid
                let initialSelectedDoctype = this.doctypes.find(doctype => doctype.id == this.initialSelectedDoctypeId);
                if (initialSelectedDoctype) {
                    this.onSelectionChange(initialSelectedDoctype);
                } else {
                    // select the first doctype, if it exists
                    if (this.doctypes.length > 0) {
                        this.onSelectionChange(this.doctypes[0]);
                    }
                }
                
                this.status = StatusComponentStatus.Normal;
            },
            err => { // fail
                console.error(err);
                this.status = StatusComponentStatus.Error;
            }
        );
    }
    
    onSelectionChange(newSelection: DocType) {
        if (this.selection === newSelection) return;
        this.selection = newSelection;
        this.selectionChange.emit(newSelection);
    }
    
    onRetry() {
        this.loadDoctypes();
    }
}
